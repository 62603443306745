import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import WcIcon from '@material-ui/icons/Wc';
//import CakeIcon from '@material-ui/icons/Cake';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderTop: "2px solid #152733"
    },
    avatar: {
        backgroundColor: "#ecf0f5",
        '& svg': {
            backgroundColor: "#fff",
            fontSize: "5rem",
            border: "2px solid #d2d6de",
            borderRadius: "50%",
            marginBottom: "10px"
        }
    },
    list: {

    }
}))

const ProfileDetail = () => {
    const userProfileState = useSelector((state) => state.userProfile)
    const classes = useStyles()
    return (
        <Paper variant="outlined">
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6">Account Profile</Typography>
            </Toolbar>
            <Divider />
            <Box my={0}>
                <Box py={4} textAlign="center" className={classes.avatar}>
                    <AccountCircleIcon />
                    <Typography variant="h6">{userProfileState.data.fullName}</Typography>
                    <Typography variant="body2">{userProfileState.data.position}</Typography>
                </Box>
                <Divider />
                <Box my={2} px={2}>
                    <Typography variant="h6">Basic Info</Typography>
                    <List dense className={classes.list}>
                        {/* <ListItem>
                            <ListItemIcon><WcIcon /></ListItemIcon>
                            <ListItemText>{userProfileState.data.gender}</ListItemText>
                        </ListItem> */}
                        <ListItem>
                            <ListItemIcon><EmailIcon /></ListItemIcon>
                            <ListItemText>{userProfileState.data.email}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><BusinessIcon /></ListItemIcon>
                            <ListItemText>{userProfileState.data.institution}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><LocationOnIcon /></ListItemIcon>
                            <ListItemText>{userProfileState.data.country}</ListItemText>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box my={2} px={2}>
                    <Typography variant="h6">Breeding Schemes</Typography>
                    <List dense className={classes.list}>
                        <ListItem>
                            <ListItemIcon><ListAltIcon /></ListItemIcon>
                            <ListItemText>You have <b>{userProfileState.data.breedingSchemes}</b> Breeding Schemes</ListItemText>
                        </ListItem>
                        {/* <ListItem>
                            <ListItemIcon><FolderSharedIcon /></ListItemIcon>
                            <ListItemText>People have shared {userProfileState.data.breedingSchemeSharedCount} Breeding Schemes with you</ListItemText>
                        </ListItem> */}
                    </List>
                </Box>
            </Box>
        </Paper >

    )
}

export default ProfileDetail
import { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        '& .MuiAutocomplete-inputRoot': {
            flexWrap: "nowrap",
        }
    }
});

const CustomPopper = function (props) {
    return (<Popper {...props} style={{ width: "auto", minWidth: "100px" }} placement='bottom-start' />)
}

const GridAutocompleteMultiField = (props) => {
    const autocompleteOptions = Object.keys(props.columnDef.lookup)
    const [value, setValue] = useState([])
    const [newValue, setNewValue] = useState([])
    const [width, setWidth] = useState(value.length < 3 ? "3ch" : (value.length + 3) + "ch")

    useEffect(() => {
        setValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value.split("; "))
        setNewValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value.split("; "))
    }, [props])

    const handleChange = (e) => {
        // eslint-disable-next-line eqeqeq                        
        if (JSON.stringify(value) !== JSON.stringify(newValue)) {
            props.handleSubmit(newValue ? newValue.join("; ") : "", value ? value.join("; ") : "", props.rowData, props.columnDef)
            setValue(newValue)
            setWidth(newValue.length < 3 ? "3ch" : (newValue.length + 3) + "ch")
        }
    }
    const classes = useStyles()
    return (
        <Autocomplete
            name={props.name}
            className={classes.root}
            onClose={handleChange}
            disabled={props.disabled}
            freeSolo
            multiple
            disableCloseOnSelect
            openOnFocus
            options={autocompleteOptions}
            PopperComponent={CustomPopper}
            style={{ ...props.columnDef.cellStyle, minWidth: width, border: 0 }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            value={newValue || []}
            //inputValue={newValue.length ? newValue.join(", ") : ""}
            onChange={(e, newValue) =>
                setNewValue(newValue)
            }
            renderTags={(selected) => selected.join(", ")}
            renderInput={(params) => (
                <TextField {...params}
                    name={props.name}
                    fullWidth
                    style={{ whiteSpace: "nowrap", textAlign: "center", flexWrap: "nowrap" }}
                    onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, props.columnDef.maxLength)
                    }}
                />
            )}
            renderOption={(option, { selected }) => (
                <Box px={0} mx={0} display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center">
                    <Checkbox style={{ margin: "0px", padding: "0px 3px 0px 0px" }} size='small' checked={selected} />
                    <Typography noWrap variant='subtitle2'>{option}</Typography>
                </Box>
            )}
        />
    )
}

export default GridAutocompleteMultiField
import {
    FETCH_ALL_BREEDING_SCHEME_REQUEST, FETCH_ALL_BREEDING_SCHEME_RESPONSE, CREATE_BREEDING_SCHEME_RESPONSE, UPDATE_BREEDING_SCHEME_RESPONSE,
    LOADING_BREEDING_SCHEME, CONFIRM_DUPLICATE_BREEDING_SCHEME, CANCEL_DUPLICATE_BREEDING_SCHEME, DUPLICATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_REMOVE_BREEDING_SCHEME, CANCEL_REMOVE_BREEDING_SCHEME, REMOVE_BREEDING_SCHEME_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    openTour: false,
    duplicateSchemeForm: {
        breedingSchemeId: "",
        open: false
    },
    removeSchemeForm: {
        breedingSchemeId: "",
        open: false
    }
}
const reducer = (breedingSchemes = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BREEDING_SCHEME_REQUEST:
            return {
                ...breedingSchemes,
                isLoading: true,
                openTour: false,
                duplicateSchemeForm: {
                    breedingSchemeId: "",
                    open: false
                },
                removeSchemeForm: {
                    breedingSchemeId: "",
                    open: false
                }
            }
        case FETCH_ALL_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                ...action.payload,
                isLoading: false
            }
        case LOADING_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                isLoading: true
            }
        case CREATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                isLoading: false
            }
        case UPDATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                isLoading: false
            }
        case CONFIRM_DUPLICATE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CANCEL_DUPLICATE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case DUPLICATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CONFIRM_REMOVE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CANCEL_REMOVE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case REMOVE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        default:
            return breedingSchemes
    }
}

export default reducer
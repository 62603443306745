import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: "#ecf0f5",
        color: '#152733',
    },
}));

const PageProgress = (props) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <Box textAlign="center">
                <img src={process.env.PUBLIC_URL + '/logo-2.png'} alt="Excellence in Breeding Platform" width="450" />
                <Box my={8}>
                    <Typography variant="h6">Breeding Scheme Manager</Typography>
                </Box>
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
    );
}

export default PageProgress
import { useState, useEffect, useCallback } from 'react'
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'
// import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { addTraits, cancelAddTraits } from '../../../actions/breedingStage'

const useStyles = makeStyles((theme) => ({
    form: {
        minWidth: "440px"
    },
    dialogTitle: {
        paddingBottom: "10px",
        marginBottom: "20px",
        borderBottom: "1px solid #e3e3e3"
    },
    textField: {
        margin: "30px 0px"
    }
}))

const AddTraitsForm = ({ crop }) => {
    const dispatch = useDispatch()
    const traitFormState = useSelector((state) => state.breedingStageForms.addTraitsForm)

    const [searchTraitCatalogValue, setSearchTraitCatalogValue] = useState("")
    const [traitCatalogOptions, setTraitCatalogOptions] = useState([])
    const [traitCatalogLoading, setTraitCatalogLoading] = useState(false)
    const debouncedValue = useDebounce(searchTraitCatalogValue, 500)

    const [traitObj, setTraitObj] = useState({
        BreedingSchemeId: "",
        PoolId: "",
        TraitCatalogId: "",
        traitType: "",
    })

    useEffect(() => {
        setTraitObj({
            BreedingSchemeId: traitFormState.BreedingSchemeId,
            PoolId: traitFormState.PoolId,
            TraitCatalogId: traitFormState.TraitCatalogId,
            traitType: traitFormState.traitType,
        })
    }, [traitFormState])

    const fetchTraitCatalog = useCallback(async () => {
        const { data } = await axios.post(`/api/trait/`, { crop: crop, search: debouncedValue })
        if (data.success) {
            setTraitCatalogOptions(data.filteredResult)
        }
        setTraitCatalogLoading(false)

    }, [debouncedValue, crop]);

    useEffect(() => {
        fetchTraitCatalog()
    }, [debouncedValue, fetchTraitCatalog])

    // const handleInputChange = (e) => {
    //     setTraitObj({
    //         ...traitObj,
    //         [e.target.name]: e.target.value
    //     })
    // }

    const handleAddTraits = (e) => {
        e.preventDefault()
        if (traitObj.TraitCatalogId)
            dispatch(addTraits(traitObj))
    }

    const handleCancelAddTraits = () => {
        dispatch(cancelAddTraits())
    }

    const classes = useStyles()

    return (
        <Dialog
            open={traitFormState.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form className={classes.form} onSubmit={handleAddTraits} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">Add {traitObj.traitType} Traits</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="TraitCatalogId"
                        name="TraitCatalogId"
                        openOnFocus
                        filterOptions={(options, state) => options}
                        options={traitCatalogOptions}
                        getOptionLabel={(option) => `${option.name} (${option.traitId})`}
                        renderOption={(option) => (
                            <Box>
                                <b>{option.name}</b> - <i>{option.crop}</i><br />
                                <small>{option.traitId}</small> | <small>{option.type}</small>
                            </Box>
                        )}
                        onInputChange={(e, newValue) => {
                            setTraitCatalogLoading(true)
                            setSearchTraitCatalogValue(newValue)
                        }}
                        onChange={(e, selectedValue) => {
                            setSearchTraitCatalogValue("")
                            setTraitCatalogLoading(false)
                            setTraitObj({
                                ...traitObj,
                                TraitCatalogId: selectedValue ? selectedValue.id : ""
                            })
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size='small'
                                required
                                label="Select Trait"
                                variant="outlined"
                                margin='dense'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {traitCatalogLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />}
                    />
                    {/* <TextField
                        autoFocus={true}
                        autoComplete="off"
                        margin="dense"
                        id="costForPhenotypingTrai1t"
                        name="costForPhenotypingTrai1t"
                        label="Cost For Phenotyping Trait per Sample"
                        value={traitObj.costForPhenotypingTrai1t}
                        className={classes.textField}
                        type="number"
                        inputProps={{
                            step: "0.01"
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        onChange={handleInputChange}
                        fullWidth
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAddTraits} color="primary" size="small" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="small"
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddTraitsForm;
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import SignInForm from '../../organisms/SignInForm'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import WelcomeSection from '../../molecules/WelcomeSection'

const useStyles = makeStyles((theme) => ({
    normalScreen: {
        height: "100%"
    },
    smallScreen: {
        height: "20%"
    },
    welcomeSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& p': {
            fontSize: "0.94rem"
        },
        '& a': {
            fontWeight: "bold",
            color: "inherit"
        }
    },
    formSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}))

const LoginPage = () => {
    const userAuthState = useSelector((state) => state.userAuth)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles()
    return (
        <Grid className={smallScreen ? classes.smallScreen : classes.normalScreen} container>
            <Grid style={{ background: "linear-gradient(180deg, rgba(21,39,51,1) 30%, rgba(43,89,47,1) 100%)" }} className={classes.welcomeSection} xs={12} md={7} item>
                <WelcomeSection />
            </Grid>
            <Grid className={classes.formSection} xs={12} md={5} item>
                <SignInForm loginFormState={userAuthState} />
            </Grid>
        </Grid>
    )
}

export default LoginPage;
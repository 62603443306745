import { useSelector, useDispatch } from 'react-redux'
import { sendApprovalRequestEmail } from '../../../actions/userAuth'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: "#ecf0f5",
        alignItems: "initial"
    },
    body: {
        maxWidth: "700px"
    },
    icon: {
        fontSize: "6rem",
        marginBottom: "10px"
    },
    button: {
        paddingLeft: "35px",
        paddingRight: "35px"
    }
}));

export default function AccountApproval() {

    const userAuth = useSelector((state) => state.userAuth)
    const dispatch = useDispatch()
    const classes = useStyles();

    const handleRequestApprovalEmail = () => {
        if (userAuth.isLoading) return;
        dispatch(sendApprovalRequestEmail())
    }

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Box my={5} textAlign="center">
                <img src={process.env.PUBLIC_URL + '/logo-2.png'} alt="Excellence in Breeding Platform" width="450" />
                <Box mt={6}>
                    <AssignmentIndIcon color="secondary" className={classes.icon} />
                    <Typography variant="h4">Account Approval!</Typography>
                </Box>
                <Box my={2} px={2} className={classes.body}>
                    <Typography variant="body1">This platform is only intended for <b>CGIAR and Partner organizations</b>. Please wait until <b>System Administrators</b> validate your account. Once approved, you will receive an email notification.</Typography>
                    <br /><br />
                    {/* <Typography variant="body2">If account approval is delayed, please click <b>Request Approval</b> to notify system administrators. </Typography>
                    <Box mt={7}>
                        <Button disabled={userAuth.isLoading} className={classes.button} variant="contained" onClick={handleRequestApprovalEmail} color="secondary">
                            Request Approval
                            {
                                userAuth.isLoading &&
                                <Box position="absolute" top="20%" right="2%">
                                    <CircularProgress color="inherit" size={20} thickness={5} />
                                </Box>
                            }
                        </Button>
                    </Box> */}
                </Box>
            </Box>
        </Backdrop>
    )
}

import { useState, useEffect, useCallback } from 'react'
import useDebounce from '../../../hooks/useDebounce'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles'
import { breedingSchemeLookups } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    form: {
        maxHeight: "445px"
    },
    dialogTitle: {
        paddingBottom: "0px"
    },
    textField: {
        margin: "10px 0px"
    }
}))

const BreedingSchemeForm = ({ data, handleBreedingScheme, handleCancel, nextStep }) => {
    const [searchBreedingPipelineValue, setSearchBreedingPipelineValue] = useState("")
    const [breedingPipelineOptions, setBreedingPipelineOptions] = useState([])
    const [breedingPipelineLoading, setBreedingPipelineLoading] = useState(false)
    const debouncedValue = useDebounce(searchBreedingPipelineValue, 500)
    const [filterOptions, setFilterOptions] = useState({
        species: "",
        organisation: ""
    })
    const [formData, setFormData] = useState({
        BreedingPipelineId: data.BreedingPipelineId,
        tier: data.tier,
        productType: data.productType
    })

    const fetchBreedingPipeline = useCallback(async () => {
        const { data } = await axios.post(`/api/breeding-pipeline/`,
            {
                crop: filterOptions.species, organisation: filterOptions.organisation, search: debouncedValue
            })
        if (data.success) {
            setBreedingPipelineOptions(data.filteredResult)
        }
        setBreedingPipelineLoading(false)

    }, [debouncedValue, filterOptions]);

    useEffect(() => {
        fetchBreedingPipeline()
    }, [debouncedValue, filterOptions, fetchBreedingPipeline])

    const handleSubmit = (e) => {
        e.preventDefault()
        handleBreedingScheme(formData)
        nextStep()
    }

    const classes = useStyles()
    return (
        <form autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent style={{ height: "370px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box pb={1} my={1} style={{ borderBottom: "1px solid #e3e3e3" }} fontSize={14} fontWeight="fontWeightBold">
                            Select Breeding Pipeline
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="species"
                            name="species"
                            openOnFocus
                            options={[...new Set(breedingPipelineOptions.map(item => item.crop))]}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => setFilterOptions({ ...filterOptions, species: newValue })}
                            renderInput={(params) => <TextField {...params} size='small' required label="Crop" variant="outlined" margin='dense' />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="organization"
                            name="organization"
                            openOnFocus
                            options={[...new Set(breedingPipelineOptions.map(item => item.organisation))]}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => setFilterOptions({ ...filterOptions, organisation: newValue })}
                            style={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} size='small' required label="Organization" variant="outlined" margin='dense' />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="BreedingPipelineId"
                            name="BreedingPipelineId"
                            filterOptions={(options, state) => options}
                            openOnFocus
                            options={breedingPipelineOptions}
                            getOptionLabel={(option) => `${option.name} (${option.breedingPipelineId})`}
                            renderOption={(option) => (
                                <Box>
                                    <b>{option.name}</b> - <i>{option.crop}</i><br />
                                    <small>{option.shortName}</small><br />
                                    <small>{option.breedingPipelineId}</small> | <small>{option.organisation}</small>
                                </Box>
                            )}
                            onInputChange={(e, newValue) => {
                                setBreedingPipelineLoading(true)
                                setSearchBreedingPipelineValue(newValue)
                            }}
                            onChange={(e, selectedValue) => {
                                setFormData({
                                    ...formData,
                                    BreedingPipelineId: selectedValue ? selectedValue.id : "",
                                    BreedingPipelineObj: selectedValue
                                })
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size='small'
                                    required
                                    label="Breeding Pipeline"
                                    variant="outlined"
                                    margin='dense'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {breedingPipelineLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pb={1} my={1} style={{ borderBottom: "1px solid #e3e3e3" }} fontSize={14} fontWeight="fontWeightBold">Breeding Scheme</Box>
                    </Grid>
                    {/* 1st Row */}
                    <Grid item xs={6}>
                        <Autocomplete
                            id="tier"
                            name="tier"
                            openOnFocus
                            options={Object.keys(breedingSchemeLookups.tier)}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => setFormData({ ...formData, tier: newValue })}
                            style={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} size='small' required label="Target Product Profile Category" variant="outlined" margin='dense' />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="productType"
                            name="productType"
                            openOnFocus
                            options={Object.keys(breedingSchemeLookups.productType)}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => setFormData({ ...formData, productType: newValue })}
                            style={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} size='small' required label="Product Type" variant="outlined" margin='dense' />}
                        />
                    </Grid>
                    {/* EoF 1st Row */}

                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: "1px solid #e3e3e3", paddingTop: "20px", marginTop: "10px" }}>
                <Button onClick={handleCancel} color="primary" size="small" autoFocus>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    size="small"
                    startIcon={<SaveIcon />}
                >
                    Next
                </Button>
            </DialogActions>
        </form>
    )
}

export default BreedingSchemeForm
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { logout } from '../../../actions/userAuth'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LoggedAccount from '../../molecules/LoggedAccount'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "rgb(85,83,156)",
        background: "radial-gradient(circle, rgba(43,89,47,1) 10%, rgba(21,39,51,1) 55%)",
        minHeight: "50px",
    },
    toolbar: {
        minHeight: "50px"
    },
    title: {
        flexGrow: 1
    },
    content: {
        fontWeight: "normal",
        fontSize: "0.75rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    }
}))

const Header = ({ content }) => {
    const userAuth = useSelector((state) => state.userAuth)
    const dispatch = useDispatch()
    const classes = useStyles()

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <AppBar className={classes.root} variant="outlined" position="static">
            <Toolbar className={classes.toolbar}>
                <Typography variant="h4" className={classes.title}>
                    <Link className={classes.link} to="/">Breeding Scheme Manager</Link>
                </Typography>
                <Box flexGrow={1} maxWidth={"65%"} marginRight={2} overflow="hidden" textOverflow="ellipsis">
                    {content && Object.keys(content).length > 0 &&
                        <Typography className={classes.content} variant="inherit">
                            Breeding Pipeline: <u><b>{content?.BreedingPipeline?.name} ({content?.BreedingPipeline.breedingPipelineId})</b></u> &nbsp;-&nbsp; Breeding Scheme: <u><b>{content.name}</b></u>
                        </Typography>
                    }
                </Box>
                <LoggedAccount className="appTour-step-1" user={userAuth.user} handleLogout={handleLogout} />
            </Toolbar>
        </AppBar >
    )
}

export default Header;
import {
    FETCH_ALL_USERS_REQUEST, FETCH_ALL_USERS_RESPONSE, CONFIRM_APPROVE_USER, CANCEL_APPROVE_USER, APPROVE_USER_RESPONSE,
    LOADING_USERS, UPDATE_USER_RESPONSE, CONFIRM_REMOVE_USER, CANCEL_REMOVE_USER, REMOVE_USER_RESPONSE,
    CONFIRM_REACTIVATE_USER, CANCEL_REACTIVATE_USER, REACTIVATE_USER_RESPONSE
} from '../constants/actionTypes'

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    removeUserForm: {
        userId: "",
        open: false
    },
    reactivateUserForm: {
        userId: "",
        open: false
    },
    approveUserForm: {
        userId: "",
        open: false
    }
}
const reducer = (users = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS_REQUEST:
            return {
                ...users,
                isLoading: true
            }
        case FETCH_ALL_USERS_RESPONSE:
            return {
                ...users,
                ...action.payload,
                isLoading: false
            }
        case LOADING_USERS:
            return {
                ...users,
                isLoading: true
            }
        case UPDATE_USER_RESPONSE:
            return {
                ...users,
                isLoading: false
            }
        case CONFIRM_REMOVE_USER:
            return {
                ...users,
                ...action.payload
            }
        case CANCEL_REMOVE_USER:
            return {
                ...users,
                ...action.payload
            }
        case REMOVE_USER_RESPONSE:
            return {
                ...users,
                ...action.payload,
                isLoading: false
            }
        case CONFIRM_REACTIVATE_USER:
            return {
                ...users,
                ...action.payload
            }
        case CANCEL_REACTIVATE_USER:
            return {
                ...users,
                ...action.payload
            }
        case REACTIVATE_USER_RESPONSE:
            return {
                ...users,
                ...action.payload,
                isLoading: false
            }
        case CONFIRM_APPROVE_USER:
            return {
                ...users,
                ...action.payload
            }
        case CANCEL_APPROVE_USER:
            return {
                ...users,
                ...action.payload
            }
        case APPROVE_USER_RESPONSE:
            return {
                ...users,
                ...action.payload,
                isLoading: false
            }
        default:
            return users
    }
}

export default reducer
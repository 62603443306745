import { useState, useEffect, useCallback } from 'react'
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save'
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableCell-root': {
            fontSize: "13px",
            whiteSpace: 'nowrap'
        }
    },
    form: {
        maxHeight: "445px"
    },
    dialogTitle: {
        paddingBottom: "0px"
    },
    textField: {
        margin: "10px 0px"
    }
}))

const SelectedTraitsTable = ({ selectedTraits, handleRemoveSelectedTrait }) => {
    const classes = useStyles()
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Trait Id</TableCell>
                        <TableCell>Trait Name</TableCell>
                        <TableCell>Crop</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Remove</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedTraits.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{row.traitId}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.crop}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>
                                <button type='button' onClick={() => handleRemoveSelectedTrait(idx)}>
                                    <b>X</b>
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const TraitSelectionForm = ({ formData, handleTraitSelection, handleCancel, nextStep, goToStep }) => {
    const [searchTraitCatalogValue, setSearchTraitCatalogValue] = useState("")
    const [traitCatalogOptions, setTraitCatalogOptions] = useState([])
    const [traitCatalogLoading, setTraitCatalogLoading] = useState(false)
    const debouncedValue = useDebounce(searchTraitCatalogValue, 500)
    const [selectedTraits, setSelectedTraits] = useState(formData.traits)
    const [traitSelectionForm, setTraitSelectionForm] = useState({
        id: "", //TraitCatalogId
        traitId: "",
        name: "",
        crop: "",
        type: "",
    })

    const fetchTraitCatalog = useCallback(async () => {
        const { data } = await axios.post(`/api/trait/`, { crop: formData?.BreedingPipelineObj?.crop, search: debouncedValue })
        if (data.success) {
            setTraitCatalogOptions(data.filteredResult)
        }
        setTraitCatalogLoading(false)

    }, [debouncedValue, formData]);

    useEffect(() => {
        fetchTraitCatalog()
    }, [debouncedValue, fetchTraitCatalog])

    const resetTraitForm = () => {
        setTraitSelectionForm({
            id: "",
            traitId: "",
            name: "",
            crop: "",
            type: "",
        })
    }

    const handleAddSelectedTrait = () => {
        setSelectedTraits([...selectedTraits, traitSelectionForm])
        setSearchTraitCatalogValue("")
        resetTraitForm()
    }

    const handleRemoveSelectedTrait = (selectedTraitIndex) => {
        setSelectedTraits(selectedTraits.filter((traitObj, idx) => idx !== selectedTraitIndex))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleTraitSelection(selectedTraits)
        nextStep()
    }

    return (
        <form id="trait-catalog-form" autoComplete='off' noValidate onSubmit={handleSubmit}>
            <DialogContent style={{ height: "370px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="TraitCatalogId"
                            name="TraitCatalogId"
                            filterOptions={(options, state) => options}
                            openOnFocus
                            options={traitCatalogOptions}
                            getOptionLabel={(option) => `${option.name} (${option.traitId})`}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={traitSelectionForm.id ? traitSelectionForm : null}
                            renderOption={(option) => (
                                <Box>
                                    <b>{option.name}</b> - <i>{option.crop}</i><br />
                                    <small>{option.traitId}</small> | <small>{option.type}</small>
                                </Box>
                            )}
                            onInputChange={(e, newValue) => {
                                setTraitCatalogLoading(true)
                                setSearchTraitCatalogValue(newValue)

                            }}
                            onChange={(e, selectedValue, reason) => {
                                if (reason === 'clear') {
                                    resetTraitForm()
                                } else {
                                    setTraitSelectionForm({
                                        ...traitSelectionForm,
                                        ...selectedValue
                                    })
                                }
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size='small'
                                    required
                                    label="Select Trait"
                                    variant="outlined"
                                    margin='dense'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {traitCatalogLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ float: "right" }}>
                            <Button
                                type='button'
                                variant="contained"
                                color="secondary"
                                size="small"
                                disabled={traitSelectionForm.id === ""}
                                onClick={handleAddSelectedTrait}
                                startIcon={<AddBoxIcon />}
                            >
                                Add Trait
                            </Button>
                        </Box>
                    </Grid>


                    <Grid item xs={12}>
                    <Box py={1} my={1} style={{ borderTop: "1px dashed #777", borderBottom: "1px dashed #777"}} fontSize={14} fontWeight="fontWeightBold">Selected Traits</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box maxHeight="210px" overflow="scroll">
                            <SelectedTraitsTable selectedTraits={selectedTraits} handleRemoveSelectedTrait={handleRemoveSelectedTrait} />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions style={{ borderTop: "1px solid #e3e3e3", paddingTop: "20px", marginTop: "10px" }}>
                <Button onClick={handleCancel} color="primary" size="small" autoFocus>
                    Cancel
                </Button>
                <Button
                    onClick={() => goToStep(1)}
                    variant='outlined'
                    color="secondary"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    size="small"
                    startIcon={<SaveIcon />}
                >
                    Next
                </Button>
            </DialogActions>
        </form>
    )
}

export default TraitSelectionForm
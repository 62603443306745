import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
//import { DatePicker } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import CountrySelector from '../../molecules/CountrySelector'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import WcIcon from '@material-ui/icons/Wc';
//import EventNoteIcon from '@material-ui/icons/EventNote'
import WorkIcon from '@material-ui/icons/Work'
import CircularProgress from '@material-ui/core/CircularProgress'
import { researchCenters, genderOptions } from '../../../constants'
import { updateProfile } from '../../../actions/users'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderTop: "2px solid #152733"
    },
    textField: {
        marginBottom: "18px",
    }
}))

const ProfileForm = () => {
    const userProfileState = useSelector((state) => state.userProfile)
    const dispatch = useDispatch()
    const [userProfile, setUserProfile] = useState({
        fullName: "",
        gender: "",
        dateOfBirth: null,
        institution: "",
        position: "",
        country: ""
    });

    const [formValidator, setFormValidator] = useState({
        hasFullName: true,
        hasInstitution: true,
        hasPosition: true,
        hasCountry: true,
    })

    useEffect(() => {
        if (userProfileState.data)
            setUserProfile({
                fullName: userProfileState.data.fullName,
                gender: userProfileState.data.gender,
                //dateOfBirth: userProfileState.data.dateOfBirth,
                institution: userProfileState.data.institution,
                position: userProfileState.data.position,
                country: userProfileState.data.country,
            })
    }, [userProfileState])

    const validateProfileForm = () => {

        const hasFullName = userProfile.fullName.trim() !== ""
        const hasInstitution = userProfile.institution.trim() !== ""
        const hasPosition = userProfile.position.trim() !== ""
        const hasCountry = userProfile.country.trim() !== ""

        if (hasFullName && hasInstitution &&
            hasPosition && hasCountry) return true
        else return false
    }

    const handleChange = (e) => {
        setUserProfile({
            ...userProfile,
            [e.target.id]: e.target.value
        })
    }

    // const handleDateChange = (date) => {
    //     setUserProfile({
    //         ...userProfile,
    //         dateOfBirth: date
    //     })
    // }

    const handleCountryChange = (country) => {
        setUserProfile({
            ...userProfile,
            country: country
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (userProfileState.isLoading) return;
        if (validateProfileForm()) {
            setFormValidator({
                hasFullName: true,
                hasInstitution: true,
                hasPosition: true,
                hasCountry: true,
            })

            dispatch(updateProfile(userProfile))

        } else {
            setFormValidator({
                hasFullName: userProfile.fullName.trim() !== "",
                hasInstitution: userProfile.institution.trim() !== "",
                hasPosition: userProfile.position.trim() !== "",
                hasCountry: userProfile.country.trim() !== "",
            })
        }
    }

    const classes = useStyles()
    return (
        <Paper variant="outlined">
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6">Edit Profile</Typography>
            </Toolbar>
            <Divider />
            <Box my={2} px={2}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="flex-end" className={classes.textField}>
                        <Grid item><AccountCircle /></Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="fullName"
                                label="Full Name"
                                disabled={userProfileState.isLoading}
                                error={!formValidator.hasFullName}
                                helperText={!formValidator.hasFullName && "Field is required!"}
                                onChange={handleChange}
                                value={userProfile.fullName}
                                placeholder="Enter Your Full Name" required fullWidth />
                        </Grid>
                    </Grid>
                    {/* <Grid className={classes.textField} container spacing={2} alignItems="flex-end">
                        <Grid item><WcIcon /></Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                                id="gender"
                                fullWidth
                                options={genderOptions}
                                disabled={userProfileState.isLoading}
                                onInputChange={(e, newValue) => {
                                    setUserProfile({
                                        ...userProfile,
                                        gender: newValue
                                    })
                                }}
                                inputValue={userProfile.gender}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="gender"
                                        //error={props.error}
                                        fullWidth
                                        label="Gender"
                                        variant="standard"
                                        inputProps={{
                                            ...params.inputProps,
                                            form: {
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid> */}
                    {/* <Grid container spacing={2} alignItems="flex-end" className={classes.textField}>
                        <Grid item><EventNoteIcon /></Grid>
                        <Grid item xs={10}>
                            <DatePicker
                                id="dateOfBirth"
                                fullWidth
                                disabled={userProfileState.isLoading}
                                autoOk
                                required={false}
                                variant="inline"
                                label="Date of Birth"
                                maxDate={Date.now()}
                                placeholder="Enter Date of Birth"
                                value={userProfile.dateOfBirth}
                                onChange={handleDateChange}
                                format="MM/dd/yyyy"
                            />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={2} alignItems="flex-end" className={classes.textField}>
                        <Grid item><BusinessIcon /></Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                                id="institution"
                                freeSolo
                                disabled={userProfileState.isLoading}
                                options={researchCenters.map((option) => option)}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.center;
                                }}
                                inputValue={userProfile.institution}
                                onInputChange={(e, newValue) => {
                                    setUserProfile({
                                        ...userProfile,
                                        institution: newValue
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="Institution"
                                        //margin="normal"
                                        disabled={userProfileState.isLoading}
                                        error={!formValidator.hasInstitution}
                                        helperText={!formValidator.hasInstitution && "Field is required!"}
                                        variant="standard"
                                        placeholder="Select/Enter Your Institution"
                                        required fullWidth />
                                )}
                                renderOption={(option) => (
                                    <Box flex={1}>
                                        <Box flexGrow={1}><Typography>{option.center}</Typography></Box>
                                        <Box flexGrow={1} display="none"><Typography display="block" variant="subtitle2">{option.location}</Typography></Box>
                                    </Box>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-end" className={classes.textField}>
                        <Grid item>
                            <WorkIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="position" label="Position"
                                onChange={handleChange}
                                disabled={userProfileState.isLoading}
                                error={!formValidator.hasPosition}
                                helperText={!formValidator.hasPosition && "Field is required!"}
                                value={userProfile.position}
                                placeholder="Enter Your Position" required fullWidth />
                        </Grid>
                    </Grid>
                    <CountrySelector
                        disabled={userProfileState.isLoading}
                        error={!formValidator.hasCountry}
                        handleChange={handleCountryChange}
                        value={userProfile.country}
                    />
                    <Box my={4}>
                        <Button
                            type="submit"
                            disabled={userProfileState.isLoading}
                            variant="contained"
                            color="secondary"
                            fullWidth>
                            Update Profile
                            {
                                userProfileState.isLoading &&
                                <Box position="absolute" top="20%" right="25%">
                                    <CircularProgress color="primary" size={20} thickness={5} />
                                </Box>
                            }
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper >
    )
}

export default ProfileForm
//import { useState, useEffect } from 'react'
//import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
//import { updateSchemeSettings } from '../../../actions/breedingSchemeSettings'
//import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
// import Paper from '@material-ui/core/Paper'
// import TextField from '@material-ui/core/TextField'
// import AutocompleteField from '../../atoms/AutocompleteField'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import Divider from '@material-ui/core/Divider'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Switch from '@material-ui/core/Switch'
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import AppsIcon from '@material-ui/icons/Apps';
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import makeStyles from '@material-ui/core/styles/makeStyles'
//import { breedingSchemeSettingLookups } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    collapsable: {
        width: "97vw",
        overflowX: 'scroll',
        paddingTop: "10px",
        borderTop: "1px solid #777"
    },
    //Brace Borders Stylesheet
    inputGroupBorderLeft: {
        borderTop: "2px solid #000",
        margin: "15px 10% 0 10%",
        width: "30%",
        height: "10px",
        '&::before': {
            content: '""',
            width: "30%",
            height: "20px",
            display: "block",
            borderTop: "2px solid #000",
            borderTopLeftRadius: "20px",
            marginLeft: "-30%",
            marginTop: "-2px"
        },
        '&::after': {
            content: '""',
            width: "33%",
            height: "20px",
            display: "block",
            borderBottom: "2px solid #000",
            borderBottomRightRadius: "20px",
            marginLeft: "100%",
            marginTop: "-42px"
        }
    },

    inputGroupBorderRight: {
        borderTop: "2px solid #000",
        margin: "15px 10% 0 10%",
        width: "30%",
        height: "10px",
        '&::before': {
            content: '""',
            width: "34%",
            height: "20px",
            display: "block",
            borderBottom: "2px solid #000",
            borderBottomLeftRadius: "20px",
            marginLeft: "-34%",
            marginTop: "-22px"
        },
        '&::after': {
            content: '""',
            width: "30%",
            height: "20px",
            display: "block",
            borderTop: "2px solid #000",
            borderTopRightRadius: "20px",
            marginLeft: "100%",
            marginTop: "-2px"
        }
    },

    inputGroup: {
        display: "inline-flex",
        '& > div': {
            padding: "8px"
        }
    },
    paper: {
        display: 'flex',
        minWidth: "219px",
        alignItems: 'center',
        borderRadius: 0,
        borderBottom: "1px solid #777",
    },
    form: {
        marginBottom: "16px"
    },
    label: {
        padding: "3px 0",
        backgroundColor: "#F1F1F1",
        textAlign: "center",
        '& h6': {
            fontSize: "0.75rem"
        }
    },
    input: {
        flex: 1,
        '& > div > .MuiAutocomplete-inputRoot': {
            //margin: "0px",
            padding: "0px"
        },
        '& .MuiInput-underline:before, .MuiInput-underline:after': {
            display: "none"
        },
        '& > .MuiInput-underline:before, > .MuiInput-underline:after, > .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: "0 !important",
        },
        '& input': {
            fontSize: "13px",
            fontWeight: "bolder",
            textAlign: "center",
            color: "#1DAB56",
        },
        '& .MuiSelect-select.MuiSelect-select': {
            fontSize: "13px",
            fontWeight: "bolder",
            textAlign: "center",
            color: "#1DAB56",
            padding: 0
        },
        '& .Mui-disabled > input': {
            color: "#000"
        }
    },
    inputAdornment: {
        margin: "0px 3px",
        '& p': {
            fontSize: "14px",
            fontWeight: "bolder",
            textAlign: "center",
            color: "#1DAB56",
        }
    },
    divider: {
        height: 28,
        margin: 0,
        background: "#999"
    },
    breadcrumb: {
        color: "#333",
        '& a': {
            textDecoration: "none",
            color: "inherit"
        }
    },
    breadcrumbIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    info: {
        position: "absolute",
        fontSize: "0.78rem"
    }
}))

const BreedingSchemeSettings = (props) => {
    // const [formOpen, setFormOpen] = useState(false)
    // const [schemeSettings, setSchemeSettings] = useState(props.data)
    // const [breedingSchemePermission, setBreedingSchemePermission] = useState("VIEWER")
    // const dispatch = useDispatch()
    // useEffect(() => {
    //     setSchemeSettings(props.data)
    //     setBreedingSchemePermission(props.permission)
    // }, [props.data, props.permission])

    // const handleInputChange = (e) => {
    //     setSchemeSettings({
    //         ...schemeSettings,
    //         [e.target.name]: e.target.value
    //     })
    // }

    // const handleSaveSchemeSettings = (updatedField) => {
    //     dispatch(updateSchemeSettings({
    //         settings: schemeSettings,
    //         updatedField: updatedField
    //     }))
    // }

    // const handleFormToggle = (e) => {
    //     setFormOpen(e.target.checked)
    // }

    const classes = useStyles()
    return (
        //Removed this class "mb={formOpen ? 2 : 0}" and replaced with "my={1}" because we no longer have BreedingScheme Settings
        <Box mx={2} my={1}>
            {/* <Box my={1} position="absolute"> */}
            <Box mt={1}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                    <Link color="inherit" to={"/"} className={classes.link}>
                        <Box display="flex">
                            <AppsIcon className={classes.breadcrumbIcon} />
                            <Typography variant="subtitle2">
                                Breeding Schemes
                            </Typography>
                        </Box>
                    </Link>
                    <Box display="flex">
                        <ViewComfyIcon className={classes.breadcrumbIcon} />
                        <Typography variant="subtitle2" color="textPrimary" className={classes.link}>
                            Breeding Stages
                        </Typography>
                    </Box>
                </Breadcrumbs>
            </Box>
            {/* <Box my={1} textAlign="center" hidden>
                <FormControlLabel                    
                    control={
                        <Switch
                            checked={formOpen}
                            onChange={handleFormToggle}
                            name="formToggler"
                            color="secondary"
                        />
                    }
                    label={
                        <>
                            <Typography variant="button">{formOpen ? "Close " : "Open "}
                                Breeding Scheme Settings
                            </Typography>
                            {props.isLoading &&
                                <CircularProgress disableShrink style={{ width: "18px", height: "18px", marginLeft: "10px" }} />
                            }
                        </>
                    }
                />
            </Box>
            <Collapse in={formOpen} className={classes.collapsable}>
                <Box mb={4} >
                    <Typography className={classes.info} variant="button" color="error">
                        * These are approximate costs; the tool doesn’t intend to be a costing tool but just
                        to reflect a high-level picture of the cost of a program.
                    </Typography>
                </Box>
                <form className={classes.form} autoComplete="off">
                    <Grid container spacing={2} wrap="nowrap">
                        {schemeSettings ?
                            <>
                                <Box pt={1} mr={2} className={classes.inputFrame} textAlign="center">
                                    <Typography display="block" variant="subtitle2">Starting Point Settings</Typography>
                                    <Box display="inline-block" className={classes.inputGroupBorderLeft}></Box>
                                    <Box display="inline-block" className={classes.inputGroupBorderRight}></Box>
                                    <Box className={classes.inputGroup}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constMaterialFromRSpipeline"
                                                    name="constMaterialFromRSpipeline"
                                                    className={classes.input}
                                                    value={schemeSettings.constMaterialFromRSpipeline}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    onChange={handleInputChange}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="180px">
                                                    <Typography variant="subtitle2">%materialFromRSpipeline</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constMaterialFromIntrogPipe"
                                                    name="constMaterialFromIntrogPipe"
                                                    className={classes.input}
                                                    value={schemeSettings.constMaterialFromIntrogPipe}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="180px">
                                                    <Typography variant="subtitle2">%materialFromIntrogPipe</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constMaterialFromExternalPipe"
                                                    name="constMaterialFromExternalPipe"
                                                    className={classes.input}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    value={schemeSettings.constMaterialFromExternalPipe}
                                                    onChange={handleInputChange}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="180px">
                                                    <Typography variant="subtitle2">%materialFromExternalPipe</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constYearsInTrainingSet"
                                                    name="constYearsInTrainingSet"
                                                    className={classes.input}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    value={schemeSettings.constYearsInTrainingSet}
                                                    onChange={handleInputChange}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="180px">
                                                    <Typography variant="subtitle2">#yearsInTrainingSet(GS)</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="90px">
                                                    <Typography variant="subtitle2">Level.1:</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <AutocompleteField
                                                    id="constLevel1"
                                                    name="constLevel1"
                                                    className={classes.input}
                                                    lookup={breedingSchemeSettingLookups.level1}
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    maxLength={12}
                                                    value={schemeSettings.constLevel1}
                                                    onChange={handleInputChange}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="90px">
                                                    <Typography variant="subtitle2">Level.2:</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <AutocompleteField
                                                    id="constLevel2"
                                                    name="constLevel2"
                                                    className={classes.input}
                                                    lookup={breedingSchemeSettingLookups.level2}
                                                    maxLength={12}
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    value={schemeSettings.constLevel2}
                                                    onChange={handleInputChange}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="90px">
                                                    <Typography variant="subtitle2">Level.3:</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <AutocompleteField
                                                    id="constLevel3"
                                                    name="constLevel3"
                                                    className={classes.input}
                                                    lookup={breedingSchemeSettingLookups.level3}
                                                    maxLength={12}
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    value={schemeSettings.constLevel3}
                                                    onChange={handleInputChange}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="90px">
                                                    <Typography variant="subtitle2">Level.4:</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <AutocompleteField
                                                    id="constLevel4"
                                                    name="constLevel4"
                                                    className={classes.input}
                                                    lookup={breedingSchemeSettingLookups.level4}
                                                    maxLength={12}
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    value={schemeSettings.constLevel4}
                                                    onChange={handleInputChange}
                                                    onBlur={(e) => parseInt(props.data[e.target.name]) !== parseInt(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box pt={1} mr={2} className={classes.inputFrame} textAlign="center">
                                    <Typography display="block" variant="subtitle2">Evaluation Strategy Settings</Typography>
                                    <Box display="inline-block" className={classes.inputGroupBorderLeft}></Box>
                                    <Box display="inline-block" className={classes.inputGroupBorderRight}></Box>
                                    <Box className={classes.inputGroup}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerFieldSpot"
                                                    name="constCostPerFieldSpot"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerFieldSpot}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerFieldSpot</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerPot"
                                                    name="constCostPerPot"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerPot}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerPot</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerTube"
                                                    name="constCostPerTube"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerTube}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerTube</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerRow"
                                                    name="constCostPerRow"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerRow}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerRow</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerPlot"
                                                    name="constCostPerPlot"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerPlot}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerPlot</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerNursery"
                                                    name="constCostPerNursery"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerNursery}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerNursery</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerPetri"
                                                    name="constCostPerPetri"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerPetri}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerPetri</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerLocation"
                                                    name="constCostPerLocation"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerLocation}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="140px">
                                                    <Typography variant="subtitle2">CostPerLocation</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box pt={1} mr={2} className={classes.inputFrame} textAlign="center">
                                    <Typography display="block" variant="subtitle2">Molecular Settings</Typography>
                                    <Box display="inline-block" className={classes.inputGroupBorderLeft}></Box>
                                    <Box display="inline-block" className={classes.inputGroupBorderRight}></Box>
                                    <Box className={classes.inputGroup}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleRFLP"
                                                    name="constCostPerSampleRFLP"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleRFLP}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleRFLP</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleAFLP"
                                                    name="constCostPerSampleAFLP"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleAFLP}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleAFLP</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleSTR"
                                                    name="constCostPerSampleSTR"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleSTR}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleSTR</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleSSR"
                                                    name="constCostPerSampleSSR"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleSSR}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleSSR</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleKASP"
                                                    name="constCostPerSampleKASP"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleKASP}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleKASP</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleGBS"
                                                    name="constCostPerSampleGBS"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleGBS}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleGBS</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleArray"
                                                    name="constCostPerSampleArray"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleArray}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleArray</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constCostPerSampleFlowC"
                                                    name="constCostPerSampleFlowC"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSampleFlowC}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">CostPerSampleFlowC</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box pt={1} mr={2} className={classes.inputFrame} textAlign="center">
                                    <Typography display="block" variant="subtitle2">Selection Intensities Settings</Typography>
                                    <Box display="inline-block" className={classes.inputGroupBorderLeft}></Box>
                                    <Box display="inline-block" className={classes.inputGroupBorderRight}></Box>
                                    <Box className={classes.inputGroup}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <TextField
                                                    id="constAnalyticsCost"
                                                    name="constAnalyticsCost"
                                                    className={classes.input}
                                                    value={schemeSettings.constAnalyticsCost}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <Box className={classes.label} width="150px">
                                                    <Typography variant="subtitle2">AnalyticsCost</Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box pt={1} className={classes.inputFrame} textAlign="center">
                                    <Typography display="block" variant="subtitle2">Crossing Strategy Settings</Typography>
                                    <Box display="inline-block" className={classes.inputGroupBorderLeft}></Box>
                                    <Box display="inline-block" className={classes.inputGroupBorderRight}></Box>
                                    <Box className={classes.inputGroup}>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerCloning</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerCloning"
                                                    name="constCostPerCloning"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerCloning}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerDoubling</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerDoubling"
                                                    name="constCostPerDoubling"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerDoubling}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostToHapInduct</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostToHapInduct"
                                                    name="constCostToHapInduct"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostToHapInduct}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerSelfCross</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerSelfCross"
                                                    name="constCostPerSelfCross"
                                                    error
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSelfCross}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPer2way</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPer2way"
                                                    name="constCostPer2way"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPer2way}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPer3way</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPer3way"
                                                    name="constCostPer3way"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPer3way}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostTo4way</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostTo4way"
                                                    name="constCostTo4way"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostTo4way}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerBC</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerBC"
                                                    name="constCostPerBC"
                                                    error
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerBC}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerTC</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerTC"
                                                    name="constCostPerTC"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerTC}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerSCA</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerSCA"
                                                    name="constCostPerSCA"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerSCA}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostToOP</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostToOP"
                                                    name="constCostToOP"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostToOP}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerInter2way</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerInter2way"
                                                    name="constCostPerInter2way"
                                                    error
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerInter2way}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Box className={classes.label} width="120px">
                                                    <Typography variant="subtitle2">CostPerInter3way</Typography>
                                                </Box>
                                                <Divider className={classes.divider} orientation="vertical" />
                                                <TextField
                                                    id="constCostPerInter3way"
                                                    name="constCostPerInter3way"
                                                    className={classes.input}
                                                    value={schemeSettings.constCostPerInter3way}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                    disabled={breedingSchemePermission === "VIEWER"}
                                                    inputProps={{
                                                        step: "0.01"
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" className={classes.inputAdornment} >$</InputAdornment>
                                                    }}
                                                    onKeyDown={(e) => e.key === "Enter" && handleSaveSchemeSettings(e.target.name)}
                                                    onBlur={(e) => parseFloat(props.data[e.target.name]) !== parseFloat(e.target.value) && handleSaveSchemeSettings(e.target.name)}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Box>
                                </Box>
                            </> :
                            <Grid item xs={12} md={12}>
                                <Paper variant="elevation">
                                    <Box p={2} textAlign="center">
                                        <Typography color="error" variant="button">Unable to find scheme settings, Please make sure you have created Breeding Scheme properly</Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        }
                    </Grid>
                </form>
            </Collapse> */}
        </Box>
    )
}

export default BreedingSchemeSettings;
import { flatten } from 'flat'
import { BreedingStageColumnProperties } from '../constants'

//Breeding Scheme Excluded Columns List
const excludedColumns = ['id', 'BreedingPipelineId', 'BreedingSchemeId', 'BreedingStageId', 'groupId',
    'columnName', 'Quality', 'Agronomic', 'Morphological', 'Phenological', 'AbioticStress', 'BioticStress', 'TechnologyId', 'MarkerSelectionId',
    'PhenotypeScreeningId', 'MultiplicationStrategyId', 'TraitCatalog', 'TraitCatalogId', 'order', 'CrossingStrategyId', 'createdAt', 'updatedAt', 'deletedAt']

const isObject = (item) => {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

//Default Breeding Scheme table header row level is 3
export const tableColumnsGenerator = (breedingSchemeData, headerRowLevel = 3, hiddenColumns) => {
    const structuredColumns = []
    const recurse = (breedingSchemeObj, rowLevel = 0) => {
        let columnObj = { columnSpan: 0, rowSpan: 0 }
        Object.keys(breedingSchemeObj).filter(objKey => (!excludedColumns.includes(objKey) && !hiddenColumns.includes(objKey))).map(objectKey => {
            let colObj = { columnSpan: 0, rowSpan: 0 }
            if (Array.isArray(breedingSchemeObj[objectKey])) {
                breedingSchemeObj[objectKey].map(arrayObj => {
                    const newColumnObj = recurse(arrayObj, rowLevel + 1)
                    colObj.columnSpan += newColumnObj.columnSpan
                    colObj.rowSpan = newColumnObj.rowSpan
                    return colObj
                })
            }
            else if (isObject(breedingSchemeObj[objectKey])) {
                colObj = recurse(breedingSchemeObj[objectKey], rowLevel + 1)
                if (breedingSchemeObj[objectKey].groupId) colObj.groupId = breedingSchemeObj[objectKey].groupId
            }
            columnObj.columnSpan += colObj.columnSpan ? colObj.columnSpan : 1
            const columnRowSpan = (headerRowLevel - (rowLevel + colObj.rowSpan))
            //Calculate the number of rows already rendered to calculate for the parent column rowspan
            columnObj.rowSpan = columnRowSpan + colObj.rowSpan
            // Check if Columns like Traits have a group Id. If so, it will be passed to the columns renderer
            const groupId = colObj.groupId ? colObj.groupId : null
            return structuredColumns.push({
                name: objectKey,
                //For Traits Column, we will include the value of the cost for phenotyping a trait
                //value: breedingSchemeObj[objectKey] ? breedingSchemeObj[objectKey].costForPhenotypingTrai1t : null,
                //For Traits Column, we will also include the code given for the trait
                code: breedingSchemeObj[objectKey] ? breedingSchemeObj[objectKey].TraitCatalog?.traitId : null,
                colSpan: colObj.columnSpan,
                rowSpan: columnRowSpan,
                level: rowLevel,
                groupId: groupId
            })
        })
        return columnObj
    }
    recurse(breedingSchemeData)
    return structuredColumns
}

//Breeding Scheme Excluded Data Columns List
const excludedDataColumns = ['id', 'BreedingPipelineId', 'BreedingSchemeId', 'BreedingStageId', 'groupId', 'MarkerSelectionId',
    'PhenotypeScreeningId', 'columnName', 'MultiplicationStrategyId', 'TechnologyId', 'TraitCatalog', 'TraitCatalogId', 'order', 'createdAt', 'updatedAt', 'deletedAt']

//Flatten Breeding Scheme JSON Data for Material Table
export const flattenBreedingSchemeData = (breedingSchemeData, hiddenColumns, permission, pipelineSettings) => {
    const [columns, data] = [[], []]

    //TODO - In the future, if the need arises to implement a dynamic lookup, use this definition
    const dynamicLookups = {}

    breedingSchemeData.forEach((stage, idx) => {
        //To Rearrange the rendered columns with the top level header, we sort the breeding stage data
        if (idx === 0) {
            let sortedStageObj = {}
            sortedStageObj = {
                stageNo: stage.stageNo,
                BreedingPopulation: { ...stage.BreedingPopulation },
                Divider1: stage.BreedingPopulation.id,
                PopulationStructure: {
                    id: stage.PopulationStructure.id,
                    recycledWithinPipeline: stage.PopulationStructure.recycledWithinPipeline,
                    fromAnotherCGIARPipeline: stage.PopulationStructure.fromAnotherCGIARPipeline,
                    fromNonCGIARPipelines: stage.PopulationStructure.fromNonCGIARPipelines,
                    fromOlderVarieties: stage.PopulationStructure.fromOlderVarieties,
                    fromLandracesOrOPVs: stage.PopulationStructure.fromLandracesOrOPVs,
                    fromTDD: stage.PopulationStructure.fromTDD,
                    totalNumberOfParents: stage.PopulationStructure.totalNumberOfParents,
                    matingDesign: stage.PopulationStructure.matingDesign,
                    numberOfCrosses: stage.PopulationStructure.numberOfCrosses,
                    numberOfIndividualsPerCross: stage.PopulationStructure.numberOfIndividualsPerCross,
                    numberOfLinesPerIndividual: stage.PopulationStructure.numberOfLinesPerIndividual,
                    numberOfSublinesPerLine: stage.PopulationStructure.numberOfSublinesPerLine,
                    numberOfTesters: stage.PopulationStructure.numberOfTesters,
                    numberOfTestcrosses: stage.PopulationStructure.numberOfTestcrosses,
                    numberOfHybrids: stage.PopulationStructure.numberOfHybrids,
                    numberOfHalfSibFamilies: stage.PopulationStructure.numberOfHalfSibFamilies,
                    numberOfFullSibFamilies: stage.PopulationStructure.numberOfFullSibFamilies,
                    numberOfPopulations: stage.PopulationStructure.numberOfPopulations,
                    numberOfComponentsPerSynthetic: stage.PopulationStructure.numberOfComponentsPerSynthetic,
                    numberOfSynthetics: stage.PopulationStructure.numberOfSynthetics,
                    totalNumberOfGenotypes: stage.PopulationStructure.totalNumberOfGenotypes,
                    typeOfParent: stage.PopulationStructure.typeOfParent,
                },
                Divider2: stage.MaterialIncrease.id,
                MaterialIncrease: {
                    multiplicationUnit: stage.MaterialIncrease.multiplicationUnit,
                    multiplicationTechnology: stage.MaterialIncrease.multiplicationTechnology,
                    typeOfExperimentalUnit: stage.MaterialIncrease.typeOfExperimentalUnit,
                    length: stage.MaterialIncrease.length,
                    width: stage.MaterialIncrease.width,
                    numberOfPlantsPerExperimentalUnit: stage.MaterialIncrease.numberOfPlantsPerExperimentalUnit,
                    numberOfExperimentalUnits: stage.MaterialIncrease.numberOfExperimentalUnits
                },
                Divider3: stage.Genotyping.id,
                Genotyping: {
                    id: stage.Genotyping.id,
                    Technologies: stage.Genotyping.Technologies,
                },
                Divider4: stage.MarkerSelection.id,
                MarkerSelection: {
                    id: stage.MarkerSelection.id,
                    noOfMarkerAssistedSelection: stage.MarkerSelection.MarkerTraits
                },
                Divider5: stage.PhenotypeScreening.id,
                PhenotypeScreening: {
                    id: stage.PhenotypeScreening.id,
                    ...stage.PhenotypeScreening.PhenotypeTraits
                },
                Divider6: stage.FieldTrialDesign.id,
                FieldTrialDesign: {
                    fieldCondition: stage.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: stage.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: stage.FieldTrialDesign.fieldLength,
                    fieldWidth: stage.FieldTrialDesign.fieldWidth,
                    fieldNumberOfPlantsPerExperimentalUnit: stage.FieldTrialDesign.fieldNumberOfPlantsPerExperimentalUnit,
                    experimentalDesign: stage.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: stage.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: stage.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: stage.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: stage.FieldTrialDesign.totalNumberOfExperimentalUnits,
                    numberOfSpatialCheckGenotypes: stage.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: stage.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: stage.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: stage.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: stage.FieldTrialDesign.trialsConnectedVia,
                },
                Divider7: stage.SelectionStrategy.id,
                SelectionStrategy: {
                    id: stage.SelectionStrategy.id,
                    QualityTraits: stage.SelectionStrategy.QualityTraits,
                    AgronomicTraits: stage.SelectionStrategy.AgronomicTraits,
                    MorphologicalTraits: stage.SelectionStrategy.MorphologicalTraits,
                    PhenologicalTraits: stage.SelectionStrategy.PhenologicalTraits,
                    AbioticStressTraits: stage.SelectionStrategy.AbioticStressTraits,
                    BioticStressTraits: stage.SelectionStrategy.BioticStressTraits
                },
                Divider8: stage.Advancement.id,
                Advancement: {
                    advancedUnit: stage.Advancement.advancedUnit,
                    numberOfUnitsAdvanced: stage.Advancement.numberOfUnitsAdvanced,
                    percentageOfUnitsAdvanced: stage.Advancement.percentageOfUnitsAdvanced
                },
                ...stage,
            }
            stage = sortedStageObj
        }

        data.push(flatten(stage))
    })

    if (data.length) {
        Object.keys(data[0]).forEach(key => {

            //Filter out the last segment of the object key
            const fieldName = key.split(".").pop()
            //Filter out excluded fields
            if (!excludedDataColumns.includes(key.split(".")[0]) && !hiddenColumns.includes(key.split(".")[0]) && !hiddenColumns.includes(fieldName)) {
                let columnProps = BreedingStageColumnProperties[fieldName]
                if (columnProps) {
                    let columnObj = {
                        title: "",
                        field: key,
                        editable: permission === 'VIEWER' ? "never" : columnProps.editable ? columnProps.editable : "always",
                        cellStyle: columnProps.styles,
                        lookup: columnProps.lookup ? columnProps.lookup : dynamicLookups[fieldName] ? dynamicLookups[fieldName] : null,
                        multi: columnProps.multi ? true : false,
                        freeSolo: columnProps.freeSolo ? true : false,
                        maxLength: columnProps.maxLength ? columnProps.maxLength : 24,
                        modelName: columnProps.modelName ? columnProps.modelName : null,
                        render: rowData => typeof rowData[key] !== "undefined" ?
                            (rowData[key] !== "" && rowData[key] !== null) ?
                                columnProps.prepend ?
                                    `${columnProps.prepend}${rowData[key].toLocaleString()}` :
                                    columnProps.multi ? rowData[key].split("; ") : rowData[key].toLocaleString() :
                                "" : ""
                    }
                    columns.push(columnObj)
                }
            }
        })
    }
    return {
        columns,
        data
    }
}

export const generateBreedingSchemeSchema = (breedingSchemeData, nodesDraggable) => {
    const bluprintSchema = []
    const nodeVerticalMargin = 75
    const nodeHorizontalMargin = 200

    bluprintSchema.push(...generateBreedingSchemeRuler(breedingSchemeData, { x: 0, y: 100 }, nodeVerticalMargin, nodesDraggable))
    const breedingStageSchema = generateBreedingStageSchema(breedingSchemeData, { x: 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable)
    bluprintSchema.push(...breedingStageSchema.schema)
    bluprintSchema.push(...generateBreedingPopulationSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 220) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateSelectionSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 2 * 223) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateCrossingSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 3 * 222) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateMolecularSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 4 * 221) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))

    return bluprintSchema
}

const generateBreedingSchemeRuler = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodesDraggable) => {
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.BreedingPopulation.year, season: stage.BreedingPopulation.season }))
        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize !== uniqueSeasons.size) {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
            schema.push({
                id: `{0-${stage.id}`,
                columnId: 0,
                type: 'rulerNode',
                draggable: nodesDraggable,
                data: {
                    label: `Year: ${stage.BreedingPopulation.year}`,
                    description: `Season: ${stage.BreedingPopulation.season}`
                },
                position: position,
            })
        }
    })
    schema.unshift({
        id: '0',
        columnId: 0,
        type: 'rulerHeader',
        draggable: nodesDraggable,
        data: { label: 'TIME', description: "", width: 120 },
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateBreedingStageSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.BreedingPopulation.year, season: stage.BreedingPopulation.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.BreedingPopulation.season.trim() && JSON.parse(st).year === stage.BreedingPopulation.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.id,
            columnId: 1,
            type: 'stageNode',
            data: {
                label: stage.BreedingPopulation.generation,
                description: ``
            },
            draggable: nodesDraggable,
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '1',
        columnId: 1,
        type: 'header',
        data: { label: 'Scheme', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return { schema, maxParallelNodes }
}

// --- TO-DO --- Implement the Blueprint for Breeding Population here

const generateBreedingPopulationSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.BreedingPopulation.year, season: stage.BreedingPopulation.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.BreedingPopulation.season.trim() && JSON.parse(st).year === stage.BreedingPopulation.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.EvaluationStrategy.id,
            columnId: 2,
            type: 'stageNode',
            data: {
                label: stage.EvaluationStrategy.experimentalDesign,
                description: `Locs/reps ${stage.EvaluationStrategy.totalNoOfLocations}/${stage.EvaluationStrategy.noOfReplicationsPerLoc}`
            },
            isHidden: emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            emptyNode: emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            selectable: !emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            draggable: nodesDraggable && !emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '2',
        columnId: 2,
        type: 'header',
        data: { label: 'Evaluation Decisions', description: "", width: (180 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateSelectionSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }
        const uniqueTraitSelectionMethods = new Set()
        const traitNames = []
        stage.SelectionStrategy.QualityTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.AgronomicTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.MorphologicalTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.PhenologicalTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.AbioticStressTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.BioticStressTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })

        schema.push({
            id: stage.SelectionStrategy.id,
            columnId: 3,
            type: "stageNode",
            data: {
                label: Array.from(uniqueTraitSelectionMethods).join(", "),
                description: traitNames.length > 0 && `[${traitNames.join(", ")}]`
            },
            isHidden: uniqueTraitSelectionMethods.size === 0,
            emptyNode: uniqueTraitSelectionMethods.size === 0,
            selectable: uniqueTraitSelectionMethods.size !== 0,
            draggable: nodesDraggable && uniqueTraitSelectionMethods.size !== 0,
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '3',
        columnId: 3,
        type: 'header',
        data: { label: 'Selection Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateCrossingSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        // schema.push({
        //     id: stage.CrossingStrategy.id,
        //     columnId: 4,
        //     type: "stageNode",
        //     data: {
        //         label: `${stage.CrossingStrategy.crossingMethod} ${!emptyValues.includes(stage.CrossingStrategy.parentCouplingMethod) && ` / ${stage.CrossingStrategy.parentCouplingMethod}`}`,
        //         description: `#Cross/#Progeny: ${stage.CrossingStrategy.totalNoOfCrosses}/${stage.CrossingStrategy.noOfProgenyPerCross} ${stage.CrossingStrategy.crossingUnit}`
        //     },
        //     isHidden: emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     emptyNode: emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     selectable: !emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     draggable: nodesDraggable && !emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     sourcePosition: "right",
        //     targetPosition: "left",
        //     position: position,
        // })
    })
    // schema.unshift({
    //     id: '4',
    //     columnId: 4,
    //     type: 'header',
    //     data: { label: 'Crossing / Multiplication Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
    //     isHidden: false,
    //     draggable: nodesDraggable,
    //     sourcePosition: "right",
    //     targetPosition: "left",
    //     position: { x: startingPosition.x - 10, y: startingPosition.y - (maxParallelNodes === 1 ? 110 : 75) },
    // })
    return schema
}

const generateMolecularSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.Molecular.id,
            columnId: 5,
            type: "stageNode",
            data: {
                label: `${stage.Molecular.molecularTechPurpose} (${stage.Molecular.molecularTechnologyAvailable})`
            },
            isHidden: emptyValues.includes(stage.Molecular.molecularTechPurpose),
            emptyNode: emptyValues.includes(stage.Molecular.molecularTechPurpose),
            selectable: !emptyValues.includes(stage.Molecular.molecularTechPurpose),
            draggable: nodesDraggable && !emptyValues.includes(stage.Molecular.molecularTechPurpose),
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '5',
        columnId: 5,
        type: 'header',
        data: { label: 'Molecular Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}
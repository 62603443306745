import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleBreedingStagesColumn } from '../../../actions/breedingStage'
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    menuItem: {
        '& .MuiTypography-body1': {
            fontSize: "0.9rem"
        },
        '& .MuiIconButton-root': {
            padding: "2px"
        }
    },
    subheader: {
        padding: "2px 10px"
    }
}))

const BreedingStageColumnToggler = (props) => {
    const dispatch = useDispatch()
    const breedingStageColumns = useSelector((state) => state.breedingStageColumns)
    const [open, setOpen] = useState(false)
    const [hiddenColumns, setHiddenColumns] = useState(breedingStageColumns.hiddenColumns)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        dispatch(toggleBreedingStagesColumn(hiddenColumns, props.breedingSchemeId, props.poolId))
    }
    const handleChange = (e) => {
        setHiddenColumns(e.target.value)
    }

    const classes = useStyles()
    return (
        <FormControl>
            <Tooltip title="Show/Hide Columns" placement="bottom">
                <IconButton id="columnToggleBtn" onClick={handleOpen}>
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>
            <Select
                labelId="mutiple-checkbox-label"
                id="mutiple-checkbox"
                multiple
                value={hiddenColumns}
                onChange={handleChange}
                input={<Input id="breeding-stage-columns" />}
                style={{ display: "none" }}
                open={open}
                onClose={handleClose}
                MenuProps={{
                    anchorEl: document.getElementById("columnToggleBtn"),
                    style: { marginTop: 60 }
                }}
            >
                {breedingStageColumns.columns.map((column) => (
                    column.type === 'column' ?
                        <MenuItem className={classes.menuItem} key={column.id} value={column.id}>
                            <Checkbox checked={!(hiddenColumns.indexOf(column.id) > -1)} />
                            <ListItemText primary={column.label} />
                        </MenuItem> : <ListSubheader className={classes.subheader} key={column.id}>{column.label}</ListSubheader>
                ))
                }
            </Select >
        </FormControl >
    )
}

export default BreedingStageColumnToggler;
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const WelcomeSection = () => {
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <Box pt={smallScreen ? 3 : 1} px={smallScreen ? 0 : 6}>
            <Box mb={smallScreen ? 2 : 4}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Excellence in Breeding Platform" width={smallScreen ? "330" : "450"} />
            </Box>
            <Typography variant="h4">Breeding Scheme Manager</Typography>
            <br />
            {!smallScreen &&
                <>
                    <Typography>
                        This tool supports the OneCGIAR Genetic Innovation to document all CGIAR_NARES Breeding Schemes
                        (Crossing, Evaluation and Selection decisions in Breeding Programs) in alignment with the Breeding Targets
                        [Market Segments (MS), Target Product Profiles (TPP), and Breeding Pipelines (BP)] as documented in
                        the Program Management Center’s (PMC) Breeding Portal.
                    </Typography>
                    <br />
                    <Typography>
                        <b>Value in documenting breeding schemes</b><br />
                        A well-rationalized breeding scheme, captured in a standardized approach, makes it easier to:
                        <ul>
                            <li>Discuss with colleagues, research leaders and funders;</li>
                            <li>Work with specialists on optimization research e.g. simulations;</li>
                            <li>Estimate genotyping need for QA/QC, MAS, GS;</li>
                            <li>Cost breeding activities across stages of testing;</li>
                            <li>Make a stronger case for additional investments and the resulting value of such investment;</li>
                            <li>Extract information for standard reports;</li>
                            <li>Monitor progress and changes made over time.</li>
                        </ul>
                    </Typography>
                    <Typography>
                        <b>Useful links to Breeding Targets:</b>
                    </Typography>
                    <br />
                    <Typography>
                        CGIAR BPDC (<a href="https://cimmyt-eibp-prd.azurewebsites.net/login" target="_blank" rel="noreferrer">cimmyt-eibp-prd.azurewebsites.net</a>).
                    </Typography>
                    <br />
                    <Typography>
                        Complementary tools in Breeding Scheme Design:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Costing tools (<a href="https://aussorgm.org.au/downloads/breeding-costing-tool/" target="_blank" rel="noreferrer">https://aussorgm.org.au/downloads/breeding-costing-tool/</a>).
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Modeling/simulation tools (<a href="https://CRAN.R-project.org/package=AlphaSimR" target="_blank" rel="noreferrer">https://CRAN.R-project.org/package=AlphaSimR</a>
                                &nbsp;or <a href="https://excellenceinbreeding.org/toolbox/tools/breeding-scheme-design-tools-and-software" rel="noreferrer" target="_blank">https://excellenceinbreeding.org/toolbox/tools/breeding-scheme-design-tools-and-software</a>).
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Analytical and data management tools <a href="https://ebs.excellenceinbreeding.org/" rel="noreferrer" target="_blank">(https://ebs.excellenceinbreeding.org/)</a>
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Open breeding scheme documenting tool beyond CGIAR-NARES: <a href="https://excellenceinbreeding.org/toolbox/tools/breeding-pipeline-manager-1" rel="noreferrer" target='_blank'>
                                    https://excellenceinbreeding.org/toolbox/tools/breeding-pipeline-manager-1
                                </a>
                            </Typography>
                        </li>

                    </ul>

                </>
            }
        </Box>
    )
}

export default WelcomeSection;
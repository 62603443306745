import {
    FETCH_ALL_BREEDING_STAGE_REQUEST, FETCH_ALL_BREEDING_STAGE_RESPONSE, FETCH_BREEDING_PIPELINE_RESPONSE,
    LOADING_BREEDING_STAGE, CREATE_BREEDING_STAGE_RESPONSE, UPDATE_BREEDING_STAGE_RESPONSE, CLEANUP_BREEDING_STAGE_REQUEST
} from '../constants/actionTypes';

const initialState = {
    data: [],
    breedingPipeline: {},
    permission: "VIEWER",
    isLoading: false,
    success: false,
    message: null,
}
const reducer = (breedingStages = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BREEDING_STAGE_REQUEST:
            return {
                ...breedingStages,
                data: [],
                permission: "VIEWER",
                isLoading: true,
            }
        case FETCH_BREEDING_PIPELINE_RESPONSE:
            return {
                ...breedingStages,
                breedingPipeline: action.payload.data
            }
        case FETCH_ALL_BREEDING_STAGE_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((bs, idx) => bs.stageNo = idx + 1)
            return {
                ...breedingStages,
                ...action.payload,
                permission: action.payload.permission,
                isLoading: false
            }
        case LOADING_BREEDING_STAGE:
            return {
                ...breedingStages,
                isLoading: true
            }
        case CREATE_BREEDING_STAGE_RESPONSE:
            return {
                ...breedingStages,
                isLoading: false
            }
        case UPDATE_BREEDING_STAGE_RESPONSE:
            return {
                ...breedingStages,
                isLoading: false
            }
        case CLEANUP_BREEDING_STAGE_REQUEST:
            return {
                ...breedingStages,
                data: [],
                permission: "VIEWER",
                isLoading: false,
            }
        default:
            return breedingStages
    }
}

export default reducer
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableCell-root': {
            fontSize: "13px",
            whiteSpace: 'nowrap'
        }
    },
    form: {
        maxHeight: "445px"
    },
    dialogTitle: {
        paddingBottom: "0px"
    },
    textField: {
        margin: "10px 0px"
    }
}))

const SelectedTraitsTable = ({ selectedTraits }) => {
    const classes = useStyles()
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Trait Id</TableCell>
                        <TableCell>Trait Name</TableCell>
                        <TableCell>Crop</TableCell>
                        <TableCell>Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedTraits.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{row.traitId}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.crop}</TableCell>
                            <TableCell>{row.type}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const ReviewForm = ({ data, handleSubmit, handleCancel, loading, goToStep }) => {
    return (
        <>
            <DialogContent style={{ width: "550px", height: "370px", display: "flex", alignItems: "center" }}>
                <Grid container spacing={2} style={{ width: "100%" }}>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Breeding Pipeline:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.BreedingPipelineObj?.name} | <small><i>{data.BreedingPipelineObj?.breedingPipelineId}</i></small></span></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Crop:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.BreedingPipelineObj?.crop}</span></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Organization:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.BreedingPipelineObj?.organisation}</span></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Target Product Profile Category:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.tier}</span></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Product Type:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.productType}</span></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2} fontSize={14}><b>Created By:</b></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box fontSize={14}><span>{data.Creator}</span></Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Box py={1} my={1} style={{ borderTop: "1px dashed #777", borderBottom: "1px dashed #777"}} fontSize={14} fontWeight="fontWeightBold">Selected Traits</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box maxHeight="180px" overflow="scroll">
                            <SelectedTraitsTable selectedTraits={data.traits} />
                        </Box>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: "1px solid #e3e3e3", paddingTop: "20px", marginTop: "10px" }}>
                <Button onClick={handleCancel} disabled={loading} color="primary" size="small" autoFocus>
                    Cancel
                </Button>
                <Button
                    onClick={() => goToStep(1)}
                    variant='outlined'
                    color="secondary"
                    size="small"
                    disabled={loading}
                    startIcon={<ArrowBackIcon />}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    type="button"
                    color="secondary"
                    disabled={loading}
                    size="small"
                    onClick={handleSubmit}
                    startIcon={loading ? <CircularProgress color="inherit" size={18} thickness={5} /> : <SaveIcon />}
                >
                    Create
                </Button>
            </DialogActions>
        </>
    )
}

export default ReviewForm
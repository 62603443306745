import StepWizard from "react-step-wizard";
import BreedingSchemeForm from './BreedingSchemeForm';
import TraitSelectionForm from './TraitSelectionForm';
import ReviewForm from './ReviewForm';
import { Box } from '@material-ui/core';
// import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'

// const useStyles = makeStyles((theme) => ({
//     form: {
//         maxWidth: "340px"
//     },
//     dialogTitle: {
//         paddingBottom: "0px",
//         marginBottom: "5px",
//         borderBottom: "1px solid #e3e3e3"
//     },
//     textField: {
//         margin: "10px 0px"
//     }
// }))

const BreedingSchemeWizard = ({ modalFormState, handleWizardFormChanges, handleSubmit, handleCloseModal }) => {

    const handleBreedingSchemeMetadata = (breedingSchemeObj) => {
        handleWizardFormChanges(breedingSchemeObj)
    }

    const handleTraitSelection = (selectedTraits) => {
        handleWizardFormChanges({ traits: selectedTraits })
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        handleSubmit(modalFormState.formData)
    }

    const BreedingSchemeFormNav = ({ currentStep, totalSteps }) => {
        const progress = Math.floor((currentStep / totalSteps) * 100)
        return (
            <Box py={2} mx={3} style={{ borderBottom: "2px solid #D1D5DB" }}>
                <Box color="#93959B" fontWeight="fontWeightBold" fontSize={14} mb={1}>{`Step: ${currentStep} of ${totalSteps}`}</Box>
                <Box display="flex" className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <Box flexGrow={1}>
                        {{
                            1: <div>
                                <Box fontWeight="fontWeightBold" fontSize={18}>New Breeding Scheme</Box>
                            </div>,
                            //Removed Trait Selection by Christian Request
                            // 2: <div>
                            //     <Box fontWeight="fontWeightBold" fontSize={18}>Traits</Box>
                            // </div>,
                            2: <div>
                                <Box fontWeight="fontWeightBold" fontSize={18}>Review Breeding Scheme</Box>
                            </div>

                        }[currentStep]}
                    </Box>

                    <Box display="flex" width="45%" justifyItems="center" className="flex items-center md:w-64">
                        <Box width="100%" mr={2}>
                            <Box mt={0.75} borderRadius={16} height="50%" bgcolor="secondary.main" style={{ width: progress + "%" }} />
                        </Box>
                        <Box fontSize={12} className="text-xs w-10 text-gray-600">{progress}%</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    // const classes = useStyles()

    return (
        <Dialog
            maxWidth="sm"
            open={modalFormState.open}
            aria-labelledby="breeding-scheme-dialog"
            aria-describedby="create-breeding-scheme"
        >
            {/* <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
                <Box display="flex">
                    <Box mr={1}>
                        <AddBoxIcon />
                    </Box>
                    Create New Breeding Scheme
                </Box>
            </DialogTitle> */}
            <StepWizard transitions={{}} nav={<BreedingSchemeFormNav />}>
                <BreedingSchemeForm data={modalFormState.formData} handleBreedingScheme={handleBreedingSchemeMetadata} handleCancel={handleCloseModal} />
                {/* <TraitSelectionForm formData={modalFormState.formData} handleTraitSelection={handleTraitSelection} handleCancel={handleCloseModal} /> */}
                <ReviewForm data={modalFormState.formData} loading={modalFormState.loading} handleCancel={handleCloseModal} handleSubmit={handleSubmitForm} />
            </StepWizard>
        </Dialog>
    )
}

export default BreedingSchemeWizard;
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { showNotification } from '../../../actions/notification'
import Box from '@material-ui/core/Box'
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import RefreshIcon from '@material-ui/icons/Refresh';
import SystemUpdateAltTwoToneIcon from '@material-ui/icons/SystemUpdateAltTwoTone';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
        display: "inline-flex",
        '& li': {
            padding: "0px",
            width: "auto",
            '& button': {
                color: "#000"
            }
        },
        '& hr': {
            margin: "0px 2px"
        }
    },
    toolbarButtons: {
        textAlign: "center",
        borderRight: "1px solid #e3e3e3",
        '& button': {
            padding: "6px"
        },
        '& .toolbar-button-text': {
            display: "inline-block",
            fontSize: "0.78em",
            marginTop: "-10px"
        }
    },
    syncButton: {
        display: "inline-block",
        width: "86px",
        textAlign: "center",
        borderLeft: "1px solid #e3e3e3",
        '& button': {
            padding: "6px"
        },
        '& .toolbar-button-text': {
            display: "inline-block",
            fontSize: "0.78em",
            marginTop: "-10px"
        }
    },
    statusText: {
        transform: "translate(0, -100%)",
        display: "inline-block",
        maxWidth: "350px",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "0.78em"
    },
    backdrop: {
        zIndex: 1201
    },
    helpMenu: {
        backgroundColor: "#333",
        opacity: "75%"
    },
    helpButtons: {
        width: "100%"
    },
    helpIcons: {
        border: "1px dotted #333",
        borderRadius: "20px",
        padding: "2px",
        marginRight: "2px"
    }
}))

const formatDate = (dateObj) => {
    const formattedDate = new Date(dateObj)
    return formattedDate.toString()
}

const BreedingSchemeToolbar = (props) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [syncStatus, setSyncStatus] = useState({
        lastSyncedDate: "",
        loading: false
    })

    useEffect(() => {
        let componentMounted = true
        axios.get(`/api/sync-service/`).then(({ data: result }) => {
            if (result.success && componentMounted) setSyncStatus({
                lastSyncedDate: result.data ? formatDate(result.data.lastSyncedDate) : "Unavailable",
                loading: false
            })
        }).catch((error) => {
            console.log(error)
        })
        return () => {
            componentMounted = false
        }
    }, [])

    const handleSyncNow = () => {
        setSyncStatus({ ...syncStatus, loading: true })
        axios.post(`/api/sync-service/`).then(({ data: result }) => {
            if (result.success) {
                setSyncStatus({ lastSyncedDate: formatDate(result.data.lastSyncedDate), loading: false })
                dispatch(showNotification({ type: "success", message: "Data synced successfully!" }))
            } else {
                dispatch(showNotification({ type: "error", message: "Data synchronization failed! Please try again later." }))
                setSyncStatus({ ...syncStatus, loading: false })
            }
        }).catch((error) => {
            setSyncStatus({ ...syncStatus, loading: false })
            dispatch(showNotification({ type: "error", message: "Data synchronization failed! Please try again later." }))
        })
    }

    const handleHelpClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'help-popover' : undefined;

    const classes = useStyles()
    return (
        <Box ml={1} py={1} flexGrow={1} className={props.className} style={{ borderTop: "1px solid #e3e3e3" }}>
            <List className={classes.list} dense disablePadding>
                <ListItem>
                    <Box className={classes.toolbarButtons} px={1}>
                        <IconButton className='appTour-step-3' onClick={props.handleAddBreedingStage}>
                            <AddBoxIcon />
                        </IconButton>
                        <span className='toolbar-button-text'>Add New</span>
                    </Box>
                    <Box className={classes.toolbarButtons} px={1}>
                        <IconButton onClick={props.handleRefreshBreedingStage}>
                            <RefreshIcon />
                        </IconButton>
                        <span className='toolbar-button-text'>Refresh</span>
                    </Box>
                    <Box className={classes.toolbarButtons} px={1}>
                        <IconButton onClick={props.handleToggleVisibility}>
                            {props.isPrivateOnly ? <ToggleOnIcon color='secondary' /> : <ToggleOffIcon />}
                        </IconButton>
                        <span className='toolbar-button-text'>{props.isPrivateOnly ? "My Schemes" : "All Schemes"}</span>
                    </Box>
                    <Box className={classes.toolbarButtons} px={1}>
                        <IconButton onClick={props.handleExportToCSV}>
                            <GetAppIcon />
                        </IconButton>
                        <span className='toolbar-button-text'>Export</span>
                    </Box>
                    <Box className={classes.toolbarButtons} px={2}>
                        <IconButton className='tourBtn' aria-describedby={id} variant="contained" color="primary" onClick={handleHelpClick}>
                            <HelpIcon color='secondary' />
                        </IconButton>
                        <span className='toolbar-button-text'>Help</span>
                        <Backdrop open={anchorEl !== null} className={classes.backdrop} />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            //className={classes.helpMenu}
                            onClose={handleHelpClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Box p={1} minWidth={100}>
                                <IconButton className={classes.helpButtons} size='small' onClick={props.handleTourOpen}>
                                    <DirectionsWalkIcon className={classes.helpIcons} fontSize='small' color='secondary' /> &nbsp;
                                    <Typography variant='body2' color='primary'>Tour</Typography>
                                </IconButton>
                            </Box>

                        </Popover>
                    </Box>
                </ListItem>
                <ListItem style={{ width: "100%" }}>
                    <Box width={"100%"} textAlign="right" alignContent="center">
                        <Tooltip placement='top' title={syncStatus.lastSyncedDate}>
                            <div className={classes.statusText}><b>Last Synced Date:</b> {syncStatus.lastSyncedDate} </div>
                        </Tooltip>
                        <Box className={classes.syncButton} ml={2} px={1}>
                            <IconButton disabled={syncStatus.loading} onClick={handleSyncNow}>
                                {syncStatus.loading ? <CircularProgress size={24} thickness={5} color='secondary' /> : <SystemUpdateAltTwoToneIcon color='secondary' />}
                            </IconButton>
                            <span className='toolbar-button-text'>{syncStatus.loading ? "Syncing..." : "Sync Now"}</span>
                        </Box>
                    </Box>
                </ListItem>
            </List>
        </Box>
    )
}

export default BreedingSchemeToolbar;
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        bottom: 0,
        position: "fixed",
        textAlign: "center",
        width: "100%",
        zIndex: 199
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <Box>
            <Box className={classes.root} py={1} bgcolor="primary.main" color="primary.contrastText">
                <Typography variant="caption">Copyright © 2024 Initiative on Accelerated Breeding (ABI) - Breeding Scheme Manager</Typography>
            </Box>
        </Box>
    )
}

export default Footer;
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_BREEDING_STAGE_REQUEST, FETCH_ALL_BREEDING_STAGE_RESPONSE, FETCH_BREEDING_PIPELINE_RESPONSE,
    LOADING_BREEDING_STAGE, CREATE_BREEDING_STAGE_RESPONSE, UPDATE_BREEDING_STAGE_RESPONSE,
    UPDATE_BREEDING_STAGE_CELLS_REQUEST, UPDATE_BREEDING_STAGE_CELLS_RESPONSE, CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST,
    CONFIRM_DUPLICATE_STAGE, CANCEL_DUPLICATE_STAGE, DUPLICATE_STAGE_RESPONSE, OPEN_ADD_GENOTYPE_TECHNOLOGY, ADD_GENOTYPE_TECHNOLOGY_RESPONSE,
    CANCEL_ADD_GENOTYPE_TECHNOLOGY, OPEN_ADD_MARKER_TRAITS, ADD_MARKER_TRAITS_RESPONSE, CANCEL_ADD_MARKER_TRAITS,
    OPEN_ADD_PHENOTYPE_TRAITS, ADD_PHENOTYPE_TRAITS_RESPONSE, CANCEL_ADD_PHENOTYPE_TRAITS, OPEN_ADD_TRAITS, ADD_TRAITS_RESPONSE, CANCEL_ADD_TRAITS,
    OPEN_UPDATE_TRAITS, UPDATE_TRAITS_RESPONSE, CANCEL_UPDATE_TRAITS, CLEANUP_BREEDING_STAGE_REQUEST,
    CONFIRM_REMOVE_ITEM, CANCEL_REMOVE_ITEM, REMOVE_ITEM_RESPONSE, BREEDING_STAGE_TOGGLE_COLUMN
} from '../constants/actionTypes';

//Action Creators

export const toggleBreedingStagesColumn = (hiddenColumns, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        //Update the UI state with latest data from server and toggle column        
        await dispatch({ type: BREEDING_STAGE_TOGGLE_COLUMN, payload: hiddenColumns })
        await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
    } catch (error) {
        console.log(error)
    }
}

export const getBreedingStages = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_REQUEST })
        const { data } = await api.fetchBreedingStages(breedingSchemeId, poolId)
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE, payload: data })
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cleanupBreedingStages = () => async (dispatch) => {
    dispatch({ type: CLEANUP_BREEDING_STAGE_REQUEST })
    // try {

    // } catch (error) {
    //     dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE })
    //     dispatch(showNotification({ type: "error", message: error.message }))
    // }
}

export const getBreedingPipeline = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingPipeline(id)
        dispatch({ type: FETCH_BREEDING_PIPELINE_RESPONSE, payload: data })
    } catch (error) {
        dispatch({ type: FETCH_BREEDING_PIPELINE_RESPONSE })
    }
}

//Expected argument is the selected Breeding Scheme Id
export const reloadBreedingStages = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingStages(breedingSchemeId, poolId)
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE, payload: data })
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
//Expected argument is the selected Breeding Scheme Id & Pool Id
export const refreshBreedingStagesTable = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch(reloadBreedingStages(breedingSchemeId, poolId))
    } catch (error) {
        console.log(error)
    }
}

export const createBreedingPool = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.createBreedingPool(breedingSchemeId, poolId)
        if (data.success) await dispatch(getBreedingPipeline(breedingSchemeId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveBreedingPool = (breedingSchemeId, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removePoolForm: {
                BreedingSchemeId: breedingSchemeId,
                PoolId: poolId,
                open: true
            },
        }
    })
}

export const cancelRemoveBreedingPool = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removePoolForm: {
                BreedingSchemeId: "",
                PoolId: "",
                open: false
            }
        }
    })
}

export const removeBreedingPool = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeBreedingPool(poolId)
        if (data.success) await dispatch(getBreedingPipeline(breedingSchemeId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createBreedingStage = (breedingStageObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.createBreedingStage(breedingStageObj)
        if (data.success) await dispatch(reloadBreedingStages(breedingStageObj.BreedingSchemeId, breedingStageObj.PoolId))
        else dispatch({ type: CREATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: CREATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

const updateBreedingStageTableCells = (breedingSchemeId, poolId) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingStages(breedingSchemeId, poolId)
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        !data.success && dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateBreedingStage = (breedingStageObj) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        const { data } = await api.updateBreedingStage(breedingStageObj)
        if (data.success) dispatch(updateBreedingStageTableCells(breedingStageObj.BreedingSchemeId, breedingStageObj.PoolId))
        else dispatch({ type: CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        !data.success && dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderBreedingStage = (orderObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.orderBreedingStage(orderObj)
        if (data.success) dispatch(reloadBreedingStages(orderObj.BreedingSchemeId))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateBreedingStage = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_STAGE, payload: {
            duplicateStageForm: {
                PoolId: poolId,
                breedingStageId: id,
                open: true
            }
        }
    })
}

export const cancelDuplicateBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_STAGE, payload: {
            duplicateStageForm: {
                PoolId: "",
                breedingStageId: "",
                open: false
            }
        }
    })
}

export const duplicateBreedingStage = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: DUPLICATE_STAGE_RESPONSE, payload: {
                duplicateStageForm: {
                    PoolId: "",
                    breedingStageId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateBreedingStage(id)
        if (data.success) dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: DUPLICATE_STAGE_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: DUPLICATE_STAGE_RESPONSE, payload: {
                duplicateStageForm: {
                    PoolId: "",
                    breedingStageId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const openAddGenotypeTechnology = (newTechnologyObj) => async (dispatch) => {
    dispatch({
        type: OPEN_ADD_GENOTYPE_TECHNOLOGY, payload: {
            addGenotypeTechnologyForm: {
                BreedingSchemeId: newTechnologyObj.BreedingSchemeId,
                PoolId: newTechnologyObj.PoolId,
                technology: newTechnologyObj.technology,
                open: true
            }
        }
    })
}

export const addGenotypeTechnology = (technologyObj) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_GENOTYPE_TECHNOLOGY_RESPONSE, payload: {
                addGenotypeTechnologyForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    technology: "",
                    open: false
                }
            }
        })
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addGenotypeTechnology(technologyObj.PoolId, technologyObj)
        if (data.success) await dispatch(reloadBreedingStages(technologyObj.BreedingSchemeId, technologyObj.PoolId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: ADD_GENOTYPE_TECHNOLOGY_RESPONSE, payload: {
                addGenotypeTechnologyForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    technology: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelAddGenotypeTechnology = () => async (dispatch) => {
    dispatch({
        type: CANCEL_ADD_GENOTYPE_TECHNOLOGY, payload: {
            addGenotypeTechnologyForm: {
                BreedingSchemeId: "",
                PoolId: "",
                technology: "",
                open: false
            }
        }
    })
}

export const confirmRemoveTechnology = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeGenotypeTechnologyForm: {
                PoolId: poolId,
                technologyGroupId: id,
                open: true
            },
        }
    })
}

export const cancelRemoveTechnology = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeGenotypeTechnologyForm: {
                PoolId: "",
                technologyGroupId: "",
                open: false
            }
        }
    })
}

export const removeTechnology = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeGenotypeTechnologyForm: {
                    PoolId: "",
                    technologyGroupId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeGenotypeTechnology(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeTraitsForm: {
                    PoolId: "",
                    traitsGroupId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const addApplication = (technologyGroupId, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addApplication(technologyGroupId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveApplication = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeGenotypeTechnologyApplicationForm: {
                PoolId: poolId,
                applicationGroupId: id,
                open: true
            },
        }
    })
}

export const cancelRemoveApplication = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeGenotypeTechnologyApplicationForm: {
                PoolId: "",
                applicationGroupId: "",
                open: false
            },
        }
    })
}

export const removeApplication = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeGenotypeTechnologyApplicationForm: {
                    PoolId: "",
                    applicationGroupId: "",
                    open: false
                },
            }
        })
        const { data } = await api.removeApplication(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeGenotypeTechnologyApplicationForm: {
                    PoolId: "",
                    applicationGroupId: "",
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const openAddMarkerTraits = (newTraitsObj) => async (dispatch) => {
    dispatch({
        type: OPEN_ADD_MARKER_TRAITS, payload: {
            addMarkerTraitsForm: {
                BreedingSchemeId: newTraitsObj.BreedingSchemeId,
                PoolId: newTraitsObj.PoolId,
                TraitCatalogId: newTraitsObj.TraitCatalogId,
                noOfMarkersAssisted: 0,
                open: true
            }
        }
    })
}

export const addMarkerTraits = (traitObj) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_MARKER_TRAITS_RESPONSE, payload: {
                addMarkerTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    noOfMarkersAssisted: 0,
                    open: false
                },
            }
        })
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addMarkerTraits(traitObj.PoolId, traitObj)
        if (data.success) await dispatch(reloadBreedingStages(traitObj.BreedingSchemeId, traitObj.PoolId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: ADD_MARKER_TRAITS_RESPONSE, payload: {
                addTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    noOfMarkersAssisted: 0,
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelAddMarkerTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_ADD_MARKER_TRAITS, payload: {
            addMarkerTraitsForm: {
                BreedingSchemeId: "",
                PoolId: "",
                TraitCatalogId: "",
                noOfMarkersAssisted: 0,
                open: false
            },
        }
    })
}

export const confirmRemoveMarkerTraits = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeMarkerTraitsForm: {
                PoolId: poolId,
                traitsGroupId: id,
                open: true
            },
        }
    })
}

export const cancelRemoveMarkerTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeMarkerTraitsForm: {
                PoolId: "",
                traitsGroupId: "",
                open: false
            },
        }
    })
}

export const removeMarkerTraits = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeMarkerTraitsForm: {
                    PoolId: poolId,
                    traitsGroupId: "",
                    open: false
                },
            }
        })
        const { data } = await api.removeMarkerTraits(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeMarkerTraitsForm: {
                    PoolId: "",
                    traitsGroupId: "",
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const openAddPhenotypeTraits = (newTraitsObj) => async (dispatch) => {
    dispatch({
        type: OPEN_ADD_PHENOTYPE_TRAITS, payload: {
            addPhenotypeTraitsForm: {
                BreedingSchemeId: newTraitsObj.BreedingSchemeId,
                PoolId: newTraitsObj.PoolId,
                TraitCatalogId: newTraitsObj.TraitCatalogId,
                phenotypeCondition: "",
                phenotypeTypeOfExpUnit: "",
                phenotypeLength: "",
                phenotypeWidth: "",
                phenoNoOfPlantsPerExpUnit: 0,
                phenotypeNoOfLocations: 0,
                phenotypeNoOfRepPerLocation: 0,
                open: true
            }
        }
    })
}

export const addPhenotypeTraits = (traitObj) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_PHENOTYPE_TRAITS_RESPONSE, payload: {
                addPhenotypeTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    phenotypeCondition: "",
                    phenotypeTypeOfExpUnit: "",
                    phenotypeLength: "",
                    phenotypeWidth: "",
                    phenoNoOfPlantsPerExpUnit: 0,
                    phenotypeNoOfLocations: 0,
                    phenotypeNoOfRepPerLocation: 0,
                    open: false
                },
            }
        })
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addPhenotypeTraits(traitObj.PoolId, traitObj)
        if (data.success) await dispatch(reloadBreedingStages(traitObj.BreedingSchemeId, traitObj.PoolId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: ADD_PHENOTYPE_TRAITS_RESPONSE, payload: {
                addPhenotypeTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    phenotypeCondition: "",
                    phenotypeTypeOfExpUnit: "",
                    phenotypeLength: "",
                    phenotypeWidth: "",
                    phenoNoOfPlantsPerExpUnit: 0,
                    phenotypeNoOfLocations: 0,
                    phenotypeNoOfRepPerLocation: 0,
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelAddPhenotypeTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_ADD_PHENOTYPE_TRAITS, payload: {
            addPhenotypeTraitsForm: {
                BreedingSchemeId: "",
                PoolId: "",
                TraitCatalogId: "",
                phenotypeCondition: "",
                phenotypeTypeOfExpUnit: "",
                phenotypeLength: "",
                phenotypeWidth: "",
                phenoNoOfPlantsPerExpUnit: 0,
                phenotypeNoOfLocations: 0,
                phenotypeNoOfRepPerLocation: 0,
                open: false
            },
        }
    })
}

export const confirmRemovePhenotypeTraits = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removePhenotypeTraitsForm: {
                PoolId: poolId,
                traitsGroupId: id,
                open: true
            },
        }
    })
}

export const cancelRemovePhenotypeTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removePhenotypeTraitsForm: {
                PoolId: "",
                traitsGroupId: "",
                open: false
            },
        }
    })
}

export const removePhenotypeTraits = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePhenotypeTraitsForm: {
                    PoolId: poolId,
                    traitsGroupId: "",
                    open: false
                },
            }
        })
        const { data } = await api.removePhenotypeTraits(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePhenotypeTraitsForm: {
                    PoolId: "",
                    traitsGroupId: "",
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const openAddTraits = (newTraitsObj) => async (dispatch) => {
    dispatch({
        type: OPEN_ADD_TRAITS, payload: {
            addTraitsForm: {
                BreedingSchemeId: newTraitsObj.BreedingSchemeId,
                PoolId: newTraitsObj.PoolId,
                TraitCatalogId: newTraitsObj.TraitCatalogId,
                traitType: newTraitsObj.traitType,
                open: true
            }
        }
    })
}

export const addTraits = (traitObj) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_TRAITS_RESPONSE, payload: {
                addTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    traitType: "",
                    open: false
                },
            }
        })
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addTraits(traitObj.PoolId, traitObj)
        if (data.success) await dispatch(reloadBreedingStages(traitObj.BreedingSchemeId, traitObj.PoolId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: ADD_TRAITS_RESPONSE, payload: {
                addTraitsForm: {
                    BreedingSchemeId: "",
                    PoolId: "",
                    TraitCatalogId: "",
                    traitType: "",
                    open: false
                },
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelAddTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_ADD_TRAITS, payload: {
            addTraitsForm: {
                BreedingSchemeId: "",
                PoolId: "",
                TraitCatalogId: "",
                traitType: "",
                open: false
            },
        }
    })
}

export const openUpdateTraits = (id, breedingSchemeId, columnName, value) => async (dispatch) => {
    dispatch({
        type: OPEN_UPDATE_TRAITS, payload: {
            updateTraitsForm: {
                traitsGroupId: id,
                BreedingSchemeId: breedingSchemeId,
                columnName: columnName,
                open: true
            }
        }
    })
}

export const updateTraits = (traitsObj) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_TRAITS_RESPONSE, payload: {
                updateTraitsForm: {
                    traitsGroupId: "",
                    BreedingSchemeId: "",
                    columnName: "",
                    open: false
                },
            }
        })
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.updateTraits(traitsObj.traitsGroupId, traitsObj)
        if (data.success) await dispatch(reloadBreedingStages(traitsObj.BreedingSchemeId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: UPDATE_TRAITS_RESPONSE, payload: {
                updateTraitsForm: {
                    traitsGroupId: "",
                    BreedingSchemeId: "",
                    columnName: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelUpdateTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_UPDATE_TRAITS, payload: {
            updateTraitsForm: {
                traitsGroupId: "",
                BreedingSchemeId: "",
                columnName: "",
                open: false
            }
        }
    })
}

export const confirmRemoveTraits = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeTraitsForm: {
                PoolId: poolId,
                traitsGroupId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeTraitsForm: {
                traitsGroupId: "",
                open: false
            }
        }
    })
}

export const removeTraits = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeTraitsForm: {
                    PoolId: poolId,
                    traitsGroupId: "",
                    open: false
                }
            }
        })

        const { data } = await api.removeTraits(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeTraitsForm: {
                    PoolId: "",
                    traitsGroupId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const addCrossingPool = (breedingSchemeId) => async (dispatch) => {
    try {
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addCrossingPool(breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemovePools = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removePoolsForm: {
                poolsGroupId: id,
                open: true
            }
        }
    })
}

export const cancelRemovePools = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removePoolsForm: {
                poolsGroupId: "",
                open: false
            }
        }
    })
}

export const removePools = (id, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolsForm: {
                    poolsGroupId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeCrossingPool(id, breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolsForm: {
                    poolsGroupId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveBreedingStage = (id, poolId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeStageForm: {
                PoolId: poolId,
                breedingStageId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeStageForm: {
                PoolId: "",
                breedingStageId: "",
                open: false
            }
        }
    })
}

export const removeBreedingStage = (id, breedingSchemeId, poolId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeStageForm: {
                    PoolId: "",
                    breedingStageId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeBreedingStage(id, poolId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId, poolId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeStageForm: {
                    PoolId: "",
                    breedingStageId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportBreedingStages = (breedingStagesObj) => async (dispatch) => {
    try {
        if (breedingStagesObj.length) {
            const { data } = await api.exportBreedingStages(breedingStagesObj[0].PoolId, breedingStagesObj)
            if (data.success) window.open(`${window.location.origin}/api/breeding-stage/export/${breedingStagesObj[0].PoolId}`)
            else dispatch(showNotification({ type: "error", message: data.message }))
        }
        else dispatch(showNotification({ type: "error", message: "Cannot export empty data!" }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBreedingStagesData, selectBreedingStagesIsLoading, selectBreedingStagesPipeline, selectBreedingSchemePermission } from '../../../selectors/breedingStageSelector'
import { selectBreedingSchemeSettingsData, selectBreedingSchemeSettingsIsLoading } from '../../../selectors/breedingSchemeSettingsSelector'
import { useParams, useHistory } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '@material-ui/core/Button'
import BreedingSchemeSettings from '../../organisms/BreedingSchemeSettings'
import BreedingStage from '../../organisms/BreedingStage'
import BreedingStageDialog from '../../organisms/BreedingStageDialog'
import AddGenotypeTechnologyForm from '../../organisms/AddGenotypeTechnologyForm'
import AddMarkerTraitsForm from '../../organisms/AddMarkerTraitsForm'
import AddPhenotypeTraitsForm from '../../organisms/AddPhenotypeTraitsForm'
import AddTraitsForm from '../../organisms/AddTraitsForm'
import TraitsForm from '../../organisms/TraitsForm'
// import { getSchemeSettings } from '../../../actions/breedingSchemeSettings'
import { getBreedingStages, getBreedingPipeline, createBreedingPool, confirmRemoveBreedingPool, cleanupBreedingStages } from '../../../actions/breedingStage';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const BreedingPoolTab = ({ pool, idx, permission, selectedPool, handleRemovePool }) => {
    return (
        <Box width={1} display="flex" flexDirection="row" alignItems="center">
            <Box flexGrow={1}>
                <span style={{ fontSize: "0.75rem", fontWeight: "bold" }}>{pool.name + " " + (idx + 1)}</span>
            </Box>
            {idx === selectedPool && permission !== 'VIEWER'
                ? <IconButton disabled={permission === 'VIEWER'} onClick={() => handleRemovePool(pool.id)}>
                    <ClearIcon style={{ fontSize: "18px", fontWeight: "bold" }} />
                </IconButton>
                : <></>
            }
        </Box>
    )
}

const BreedingStagePage = () => {
    const history = useHistory()
    const breedingSchemePermissionState = useSelector(selectBreedingSchemePermission)
    const breedingSchemeSettingsDataState = useSelector(selectBreedingSchemeSettingsData)
    const breedingSchemeSettingsIsLoadingState = useSelector(selectBreedingSchemeSettingsIsLoading)
    const breedingStagePipelineState = useSelector(selectBreedingStagesPipeline)
    const breedingStageDataState = useSelector(selectBreedingStagesData)
    const breedingStageIsLoadingState = useSelector(selectBreedingStagesIsLoading)

    const dispatch = useDispatch()
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(getBreedingPipeline(id))
        }
        return () => {
            dispatch(cleanupBreedingStages())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [selectedPool, setSelectedPool] = useState(0);

    useEffect(() => {
        if (breedingStagePipelineState && Object.keys(breedingStagePipelineState).length) {
            if (breedingStagePipelineState.id === id) {
                if (selectedPool >= breedingStagePipelineState.Pools.length) {
                    setSelectedPool(breedingStagePipelineState.Pools.length - 1)
                }
                else {
                    //console.log(id, breedingStagePipelineState.Pools[selectedPool].id)
                    dispatch(getBreedingStages(id, breedingStagePipelineState.Pools[selectedPool].id))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedPool, breedingStagePipelineState])

    const handleChange = (event, newValue) => {
        setSelectedPool(newValue);
    };

    const handleAddPool = async () => {
        await dispatch(createBreedingPool(breedingStagePipelineState.Pools[selectedPool].BreedingSchemeId, breedingStagePipelineState.Pools[selectedPool].id))
    }

    const handleRemovePool = async (poolId) => {
        await dispatch(confirmRemoveBreedingPool(breedingStagePipelineState.Pools[selectedPool].BreedingSchemeId, poolId))
    }

    return (
        <>
            <Header content={breedingStagePipelineState} />
            {breedingStagePipelineState ?
                <Box mb={2} pb={7}>
                    <BreedingStageDialog breedingSchemeId={id} />
                    {/* Load Add Genotype Technology Form */}
                    <AddGenotypeTechnologyForm />
                    {/* Load Add Marker-Assisted Selection Trait Form */}
                    <AddMarkerTraitsForm crop={breedingStagePipelineState?.BreedingPipeline?.crop} />
                    {/* Load Add Phenotype Screening Trait Form */}
                    <AddPhenotypeTraitsForm crop={breedingStagePipelineState?.BreedingPipeline?.crop} />
                    {/* Load Add Traits Form */}
                    <AddTraitsForm crop={breedingStagePipelineState?.BreedingPipeline?.crop} />
                    {/* Load Update Traits Form */}
                    <TraitsForm />

                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <BreedingSchemeSettings data={breedingSchemeSettingsDataState} permission={breedingSchemePermissionState} isLoading={breedingSchemeSettingsIsLoadingState} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box bgcolor="#F2F1F2" color="#000" style={{ display: breedingStagePipelineState?.productType === "Hybrid" ? "flex" : "none", flexDirection: "row", borderTop: "1px solid #b3b3b3", borderBottom: "1px solid #b3b3b3" }}>
                                <Tabs
                                    style={{ display: "inline-block", overflowX: "auto" }}
                                    value={selectedPool} onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons='off'
                                    aria-label="Breeding stage pools">
                                    {breedingStagePipelineState?.Pools?.map((pool, idx) =>
                                        <Tab key={idx} style={{ fontSize: "0.75rem", fontWeight: "bold", maxHeight: "45px" }} label={breedingStagePipelineState.Pools.length > 1 ? <BreedingPoolTab pool={pool} idx={idx} permission={breedingSchemePermissionState} selectedPool={selectedPool} handleRemovePool={handleRemovePool} /> : "Pool 1"} {...a11yProps(idx)} />
                                    )}
                                </Tabs>
                                <IconButton disabled={breedingSchemePermissionState === "VIEWER"} onClick={handleAddPool} color="primary"><AddBoxIcon /></IconButton>
                            </Box>
                            {breedingStagePipelineState?.Pools?.map((pool, idx) =>
                                <TabPanel key={idx} value={selectedPool} index={idx}>
                                    <BreedingStage
                                        permission={breedingSchemePermissionState}
                                        breedingScheme={breedingStagePipelineState}
                                        breedingStagesData={breedingStageDataState}
                                        isLoading={breedingStageIsLoadingState}
                                        pipelineSettings={breedingSchemeSettingsDataState}
                                        breedingSchemeId={id}
                                        poolId={pool.id}
                                    />
                                </TabPanel>
                            )}
                        </Grid>
                    </Grid>
                </Box> :
                <Box my={10} py={5} textAlign="center">
                    <h4>Breeding Scheme Not Found!</h4>
                    <Button style={{ padding: "0px 35px" }} variant="contained" onClick={() => history.push("/")} color="secondary">
                        Return Home
                    </Button>
                </Box>
            }
            <Footer />
        </>
    )
}

export default BreedingStagePage;
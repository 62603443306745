import { useState, useEffect, useCallback } from 'react'
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'
import AddBoxIcon from '@material-ui/icons/AddBox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { addPhenotypeTraits, cancelAddPhenotypeTraits } from '../../../actions/breedingStage'

const useStyles = makeStyles((theme) => ({
    form: {
        minWidth: "440px"
    },
    dialogTitle: {
        paddingBottom: "10px",
        marginBottom: "20px",
        borderBottom: "1px solid #e3e3e3"
    },
    textField: {
        margin: "30px 0px"
    }
}))

const AddPhenotypeTraitsForm = ({ crop }) => {
    const dispatch = useDispatch()
    const traitFormState = useSelector((state) => state.breedingStageForms.addPhenotypeTraitsForm)

    const [searchTraitCatalogValue, setSearchTraitCatalogValue] = useState("")
    const [traitCatalogOptions, setTraitCatalogOptions] = useState([])
    const [traitCatalogLoading, setTraitCatalogLoading] = useState(false)
    const debouncedValue = useDebounce(searchTraitCatalogValue, 500)

    const [traitObj, setTraitObj] = useState({
        BreedingSchemeId: "",
        PoolId: "",
        TraitCatalogId: "",
        phenotypeCondition: "",
        phenotypeTypeOfExpUnit: "",
        phenotypeLength: "",
        phenotypeWidth: "",
        phenoNoOfPlantsPerExpUnit: 0,
        phenotypeNoOfLocations: 0,
        phenotypeNoOfRepPerLocation: 0,
    })

    useEffect(() => {
        setTraitObj({
            BreedingSchemeId: traitFormState.BreedingSchemeId,
            PoolId: traitFormState.PoolId,
            TraitCatalogId: traitFormState.TraitCatalogId,
            phenotypeCondition: traitFormState.phenotypeCondition,
            phenotypeTypeOfExpUnit: traitFormState.phenotypeTypeOfExpUnit,
            phenotypeLength: traitFormState.phenotypeLength,
            phenotypeWidth: traitFormState.phenotypeWidth,
            phenoNoOfPlantsPerExpUnit: traitFormState.phenoNoOfPlantsPerExpUnit,
            phenotypeNoOfLocations: traitFormState.phenotypeNoOfLocations,
            phenotypeNoOfRepPerLocation: traitFormState.phenotypeNoOfRepPerLocation,
        })
    }, [traitFormState])

    const fetchTraitCatalog = useCallback(async () => {
        const { data } = await axios.post(`/api/trait/`, { crop: crop, search: debouncedValue })
        if (data.success) {
            setTraitCatalogOptions(data.filteredResult)
        }
        setTraitCatalogLoading(false)

    }, [debouncedValue, crop]);

    useEffect(() => {
        fetchTraitCatalog()
    }, [debouncedValue, fetchTraitCatalog])

    const handleAddTraits = (e) => {
        e.preventDefault()
        if (traitObj.TraitCatalogId)
            dispatch(addPhenotypeTraits(traitObj))
    }

    const handleCancelAddTraits = () => {
        dispatch(cancelAddPhenotypeTraits())
    }

    const classes = useStyles()

    return (
        <Dialog
            open={traitFormState.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form className={classes.form} onSubmit={handleAddTraits} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">Add Phenotype Screening Traits</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="TraitCatalogId"
                        name="TraitCatalogId"
                        filterOptions={(options, state) => options}
                        openOnFocus
                        options={traitCatalogOptions}
                        getOptionLabel={(option) => `${option.name} (${option.traitId})`}
                        renderOption={(option) => (
                            <Box>
                                <b>{option.name}</b> - <i>{option.crop}</i><br />
                                <small>{option.traitId}</small> | <small>{option.type}</small>
                            </Box>
                        )}
                        onInputChange={(e, newValue) => {
                            setTraitCatalogLoading(true)
                            setSearchTraitCatalogValue(newValue)
                        }}
                        onChange={(e, selectedValue) => {
                            setSearchTraitCatalogValue("")
                            setTraitCatalogLoading(false)
                            setTraitObj({
                                ...traitObj,
                                TraitCatalogId: selectedValue ? selectedValue.id : ""
                            })
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size='small'
                                required
                                label="Select Trait"
                                variant="outlined"
                                margin='dense'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {traitCatalogLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAddTraits} color="primary" size="small" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="small"
                        startIcon={<AddBoxIcon />}
                    >
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddPhenotypeTraitsForm;
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        maxWidth: 250,
        paddingTop: "7px",
        border: '1px solid #dadde9',
    },
    arrow: {
        color: theme.palette.primary.main,
    },
}))(Tooltip);

const ColumnTooltip = (props) => {

    return <CustomTooltip arrow placement="top-end" title={
        <>
            <Typography variant="button" style={{ fontSize: 11, fontWeight: "bold" }}>
                {props.title}
            </Typography>
            <Divider variant="fullWidth" orientation="horizontal" style={{ backgroundColor: "#e3e3e3" }} />
            <Box pt={1} fontSize={12} lineHeight={1.5} fontWeight="400">
                {props.description}
            </Box>

        </>
    } >
        {props.children}
    </CustomTooltip>
}

export default ColumnTooltip
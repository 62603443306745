import { BREEDING_STAGE_TOGGLE_COLUMN } from '../constants/actionTypes';

const initialState = {
    columns: [
        { id: 1, label: 'Main Headers', type: "subheader" },
        { id: 'BreedingPopulation', label: 'Breeding Population', type: "column" },
        { id: 'PopulationStructure', label: 'Crossing and Population Structure', type: "column" },
        { id: 'MaterialIncrease', label: 'Seed/Material Increase', type: "column" },
        { id: 'Genotyping', label: 'Genotyping', type: "column" },
        { id: 'MarkerSelection', label: 'Marker-Assisted Selection', type: "column" },
        { id: 'PhenotypeScreening', label: 'Phenotype Screening', type: "column" },
        { id: 'FieldTrialDesign', label: 'Trial Design', type: "column" },
        { id: 'SelectionStrategy', label: 'Phenotyping, Evaluation & Selection', type: "column" },
        { id: 'Advancement', label: 'Advancement', type: "column" },
        // { id: 2, label: 'Trait Columns', type: "subheader" },
        // { id: 'selectionUnit', label: 'Selection Unit', type: "column" },
        // { id: 'selectionValue', label: 'Selection Value', type: "column" },
        // { id: 'breedingSurrogate', label: 'Breeding Surrogate', type: "column" },
        // { id: 'noOfEnvironments', label: 'No. Of Environments', type: "column" },
        // { id: 'selectionMethod', label: 'Selection Method', type: "column" },
    ],
    hiddenColumns: []
}
const reducer = (breedingStageColumns = initialState, action) => {
    switch (action.type) {
        case BREEDING_STAGE_TOGGLE_COLUMN:
            return {
                ...breedingStageColumns,
                hiddenColumns: action.payload
            }
        default:
            return breedingStageColumns
    }
}

export default reducer
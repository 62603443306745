import {
    CONFIRM_DUPLICATE_STAGE, CANCEL_DUPLICATE_STAGE, DUPLICATE_STAGE_RESPONSE,
    OPEN_ADD_GENOTYPE_TECHNOLOGY, ADD_GENOTYPE_TECHNOLOGY_RESPONSE, CANCEL_ADD_GENOTYPE_TECHNOLOGY,
    OPEN_ADD_MARKER_TRAITS, ADD_MARKER_TRAITS_RESPONSE, CANCEL_ADD_MARKER_TRAITS,
    OPEN_ADD_PHENOTYPE_TRAITS, ADD_PHENOTYPE_TRAITS_RESPONSE, CANCEL_ADD_PHENOTYPE_TRAITS,
    OPEN_ADD_TRAITS, ADD_TRAITS_RESPONSE, CANCEL_ADD_TRAITS,
    OPEN_UPDATE_TRAITS, UPDATE_TRAITS_RESPONSE, CANCEL_UPDATE_TRAITS,
    CONFIRM_REMOVE_ITEM, CANCEL_REMOVE_ITEM, REMOVE_ITEM_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    success: false,
    message: null,
    removePoolForm: {
        BreedingSchemeId: "",
        PoolId: "",
        open: false
    },
    duplicateStageForm: {
        PoolId: "",
        breedingStageId: "",
        open: false
    },
    removeStageForm: {
        PoolId: "",
        breedingStageId: "",
        open: false
    },
    addGenotypeTechnologyForm: {
        BreedingSchemeId: "",
        PoolId: "",
        technology: "",
        open: false
    },
    removeGenotypeTechnologyForm: {
        PoolId: "",
        technologyGroupId: "",
        open: false
    },
    removeGenotypeTechnologyApplicationForm: {
        PoolId: "",
        applicationGroupId: "",
        open: false
    },
    addMarkerTraitsForm: {
        BreedingSchemeId: "",
        PoolId: "",
        TraitCatalogId: "",
        noOfMarkersAssisted: 0,
        open: false
    },
    removeMarkerTraitsForm: {
        PoolId: "",
        traitsGroupId: "",
        open: false
    },
    addPhenotypeTraitsForm: {
        BreedingSchemeId: "",
        PoolId: "",
        TraitCatalogId: "",
        phenotypeCondition: "",
        phenotypeTypeOfExpUnit: "",
        phenotypeLength: "",
        phenotypeWidth: "",
        phenoNoOfPlantsPerExpUnit: 0,
        phenotypeNoOfLocations: 0,
        phenotypeNoOfRepPerLocation: 0,
        open: false
    },
    removePhenotypeTraitsForm: {
        PoolId: "",
        traitsGroupId: "",
        open: false
    },
    addTraitsForm: {
        BreedingSchemeId: "",
        PoolId: "",
        TraitCatalogId: "",
        traitType: "",
        open: false
    },
    updateTraitsForm: {
        traitsGroupId: "",
        BreedingSchemeId: "",
        PoolId: "",
        columnName: "",
        open: false
    },
    removeTraitsForm: {
        PoolId: "",
        traitsGroupId: "",
        open: false
    },
    removePoolsForm: {
        poolsGroupId: "",
        open: false
    }
}
const reducer = (breedingStageForms = initialState, action) => {
    switch (action.type) {
        case CONFIRM_DUPLICATE_STAGE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_DUPLICATE_STAGE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case DUPLICATE_STAGE_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_ADD_GENOTYPE_TECHNOLOGY:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case ADD_GENOTYPE_TECHNOLOGY_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_ADD_GENOTYPE_TECHNOLOGY:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_ADD_MARKER_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case ADD_MARKER_TRAITS_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_ADD_MARKER_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_ADD_PHENOTYPE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case ADD_PHENOTYPE_TRAITS_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_ADD_PHENOTYPE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_ADD_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case ADD_TRAITS_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_ADD_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_UPDATE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case UPDATE_TRAITS_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_UPDATE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CONFIRM_REMOVE_ITEM:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_REMOVE_ITEM:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case REMOVE_ITEM_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        default:
            return breedingStageForms
    }
}

export default reducer
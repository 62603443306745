import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Navigation from '../../organisms/Navigation'
import Typography from '@material-ui/core/Typography'
import AppsIcon from '@material-ui/icons/Apps'
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        color: "#333",
        '& a': {
            textDecoration: "none",
            color: "inherit"
        }
    },
    breadcrumbIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    toolbar: {
        borderTop: "2px solid #152733",
        minHeight: "48px"
    },
    toolbarTitle: {
        marginLeft: "8px",
        fontWeight: "bold"
    },
    keyword: {
        marginRight: "8px",
        textDecoration: "underline",
        fontWeight: "bold"
    },
}))

const VocabularyPage = () => {
    const classes = useStyles()
    return (
        <>
            <Header />
            <Box my={2} pb={7} display="flex">
                <Navigation />
                <Box ml={2} width="calc(100% - 95px)">
                    {/* Breadcrumbs */}
                    <Box px={2} mb={2}>
                        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                            <Link color="inherit" to="/" className={classes.link}>
                                <Box display="flex">
                                    <AppsIcon className={classes.breadcrumbIcon} />
                                    <Typography variant="subtitle2">
                                        Breeding Schemes
                                    </Typography>
                                </Box>
                            </Link>
                            <Box display="flex">
                                <MenuBookRoundedIcon className={classes.breadcrumbIcon} />
                                <Typography variant="subtitle2" color="textPrimary" className={classes.link}>
                                    Vocabulary Help Page
                                </Typography>
                            </Box>
                        </Breadcrumbs>
                    </Box>
                    {/* End of Breadcrumbs */}
                    <Paper variant="outlined">
                        <Toolbar className={classes.toolbar}>
                            <MenuBookRoundedIcon />
                            <Typography className={classes.toolbarTitle} variant="h6">Vocabulary</Typography>
                        </Toolbar>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid md={6} xs={12} item>
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Breeding program:
                                    </Typography>
                                    <Typography className={classes.definition} variant="body2" display="inline">
                                        High level entity comprising the effort of multiple breeders and supporting staff that
                                        handle one or more breeding pipelines serving different target segments.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Breeder:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Scientist with knowledge of agronomy, genetics, pathology, among other scientific areas in
                                        charge of managing a breeding pipeline with the support of topic-experts and handover
                                        outputs to other pipelines.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Breeding Pipeline:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        is one of the following research efforts:
                                    </Typography>
                                    <ul>
                                        <li>
                                            <Typography variant="body2">Trait/diversity introgression of wild/exotic/external germplasm into early or advanced
                                                elite germplasm to handover elite enriched materials.</Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">Cyclical improvement of closed elite populations for genetic gains
                                                and early identification of potential products.</Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">Extensive product evaluation and release of stable and high performing varieties.</Typography>
                                        </li>
                                    </ul>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Market/target segment:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Description of the targeted market where products will be placed in terms of the
                                        target population of environments features, client features, and the general product description.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Target population of environments:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        The agroclimatic and management description of the farms where
                                        the final varieties/hybrids/lines aim to be grown.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Product concept/profile:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        The trait description of the variety/hybrid/line intended to be released by the
                                        breeding program specifying the trait thresholds, trait units, and benchmark materials for each trait.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Quality trait:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Phenotype of a feature associated to the degree of excellence of something.
                                        For example, protein type, protein content, starch content, zinc content, etc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Output trait:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Phenotype of a feature associated to the final product.
                                        For example, yield per hectare, green matter, bunches per plant, etc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Survival trait:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Phenotype of a feature associated to the health of the plant due to abiotic or abiotic stresses.
                                        For example, rust infection, virus resistance, drought resistance, etc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Agronomic trait:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Phenotype of a feature associated to the performance of the plant in the testing environment.
                                        For example, plant height, plant architecture, plant maturity, flowering time, etc.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Breeding scheme:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        Document expressing the crossing, evaluation and selection decisions across a stage-gate
                                        system of a breeding pipeline.
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box my={2} px={2}>
                                    <Typography className={classes.keyword} color="primary" variant="button" display="inline">
                                        Stage-gate system:
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        High level representation of a breeding program highlighting how different breeding pipelines
                                        fit together and some key seasons of the breeding schemes associated to such pipelines.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Paper >
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default VocabularyPage
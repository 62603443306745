import { useState, useEffect } from 'react'
import SelectField from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography'

const GridSelectMultiField = (props) => {
    const [value, setValue] = useState([])
    const [width, setWidth] = useState(value.length < 3 ? "3ch" : (value.length + 3) + "ch")

    useEffect(() => {
        //console.log(props)
        setValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value.split("; "))
    }, [props])

    const handleChange = (e) => {
        // eslint-disable-next-line eqeqeq
        if (value != e.target.value) {
            props.handleSubmit(e.target.value.join("; "), value ? value.join("; ") : "", props.rowData, props.columnDef)
            setValue(e.target.value)
            setWidth(e.target.value.length < 3 ? "3ch" : (e.target.value.length + 3) + "ch")
        }
    }

    return (
        <SelectField
            name={props.name}
            autoWidth
            multiple={true}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            inputProps={{
                value: value || []
            }}
            style={{ ...props.columnDef.cellStyle, minWidth: width }}
        >
            {Object.keys(props.columnDef.lookup).map(objKey =>
                <MenuItem key={objKey} value={objKey}>
                    <Checkbox checked={value.indexOf(objKey) > -1} />
                    <Typography noWrap variant='subtitle2'>
                        {props.columnDef.lookup[objKey]}
                    </Typography>
                </MenuItem>
            )}
        </SelectField>
    )
}

export default GridSelectMultiField
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_BREEDING_SCHEME_REQUEST, FETCH_ALL_BREEDING_SCHEME_RESPONSE, LOADING_BREEDING_SCHEME,
    CREATE_BREEDING_SCHEME_RESPONSE, UPDATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_DUPLICATE_BREEDING_SCHEME, CANCEL_DUPLICATE_BREEDING_SCHEME, DUPLICATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_REMOVE_BREEDING_SCHEME, CANCEL_REMOVE_BREEDING_SCHEME, REMOVE_BREEDING_SCHEME_RESPONSE
} from '../constants/actionTypes';

//Action Creators
export const getBreedingSchemes = (query) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_BREEDING_SCHEME_REQUEST })
        const { data } = await api.fetchBreedingSchemes(query)
        //Trigger the guided tour for new users
        dispatch({ type: FETCH_ALL_BREEDING_SCHEME_RESPONSE, payload: { openTour: (!data.data.count && query.search === "") } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
        return data;
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createBreedingScheme = (breedingSchemeObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME })
        await api.createBreedingScheme(breedingSchemeObj).then(({ data }) => {
            dispatch({ type: CREATE_BREEDING_SCHEME_RESPONSE })
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        })
    } catch (error) {
        dispatch({ type: CREATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateBreedingScheme = (breedingSchemeObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME })
        api.updateBreedingScheme(breedingSchemeObj.id, breedingSchemeObj).then(({ data }) => {
            dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        })
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderBreedingScheme = (orderObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.orderBreedingScheme(orderObj)
        if (data.success) dispatch(getBreedingSchemes(orderObj.BreedingPipelineId, queryObj))
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateBreedingScheme = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_BREEDING_SCHEME, payload: {
            duplicateSchemeForm: {
                breedingSchemeId: id,
                open: true
            }
        }
    })
}

export const cancelDuplicateBreedingScheme = () => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_BREEDING_SCHEME, payload: {
            duplicateSchemeForm: {
                breedingSchemeId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const duplicateBreedingScheme = (id, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME })
        dispatch({
            type: DUPLICATE_BREEDING_SCHEME_RESPONSE, payload: {
                duplicateSchemeForm: {
                    breedingSchemeId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateBreedingScheme(id)
        if (data.success) {
            //TODO - If success reload breeding scheme table
            //await dispatch(getBreedingSchemes(breedingPipelineId, queryObj))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        return data.success
    } catch (error) {
        dispatch({
            type: DUPLICATE_BREEDING_SCHEME_RESPONSE, payload: {
                duplicateSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveBreedingScheme = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_BREEDING_SCHEME, payload: {
            removeSchemeForm: {
                breedingSchemeId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveBreedingScheme = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_BREEDING_SCHEME, payload: {
            removeSchemeForm: {
                breedingSchemeId: "",
                open: false
            }
        }
    })
}

export const removeBreedingScheme = (id, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME })
        dispatch({
            type: REMOVE_BREEDING_SCHEME_RESPONSE, payload: {
                removeSchemeForm: {
                    breedingSchemeId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeBreedingScheme(id)
        if (data.success) {
            //TODO: Reload grid
            //await dispatch(getBreedingSchemes(breedingPipelineId, queryObj))            
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: REMOVE_BREEDING_SCHEME_RESPONSE, payload: {
                removeSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportBreedingSchemes = () => async (dispatch) => {
    try {
        const { data } = await api.exportBreedingSchemes()
        if (data.success) window.open(`${window.location.origin}/api/breeding-scheme/export`)
        else dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import AddBoxIcon from '@material-ui/icons/AddBox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { addGenotypeTechnology, cancelAddGenotypeTechnology } from '../../../actions/breedingStage'
import { genotypingTechnologyLookups } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    form: {
        minWidth: "340px"
    },
    dialogTitle: {
        paddingBottom: "10px",
        marginBottom: "20px",
        borderBottom: "1px solid #e3e3e3"
    },
    textField: {
        margin: "30px 0px"
    }
}))

const AddGenotypeTechnologyForm = () => {
    const dispatch = useDispatch()
    const geneotypeTechnologyFormState = useSelector((state) => state.breedingStageForms.addGenotypeTechnologyForm)

    const [technologyObj, setTechnologyObj] = useState({
        BreedingSchemeId: "",
        PoolId: "",
        technology: "",
    })

    useEffect(() => {
        setTechnologyObj({
            BreedingSchemeId: geneotypeTechnologyFormState.BreedingSchemeId,
            PoolId: geneotypeTechnologyFormState.PoolId,
            technology: geneotypeTechnologyFormState.technology
        })
    }, [geneotypeTechnologyFormState])


    const handleAddTechnology = (e) => {
        e.preventDefault()
        if (technologyObj.technology)
            dispatch(addGenotypeTechnology(technologyObj))
    }

    const handleCancelAddTechnology = () => {
        dispatch(cancelAddGenotypeTechnology())
    }

    const classes = useStyles()

    return (
        <Dialog
            open={geneotypeTechnologyFormState.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form className={classes.form} onSubmit={handleAddTechnology} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">Add Genotyping Technology</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="technology"
                        name="technology"
                        openOnFocus
                        options={Object.keys(genotypingTechnologyLookups)}
                        getOptionLabel={(option) => genotypingTechnologyLookups[option]}
                        onChange={(e, newValue) => setTechnologyObj({ ...technologyObj, technology: newValue })}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size='small' required label="Technology" variant="outlined" margin='dense' />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAddTechnology} color="primary" size="small" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="small"
                        startIcon={<AddBoxIcon />}
                    >
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddGenotypeTechnologyForm;
import { useDispatch, useSelector } from 'react-redux'
import UserDialog from '../../molecules/UserDialog'
import {
    cancelDuplicateBreedingStage, duplicateBreedingStage, removeBreedingStage, cancelRemoveBreedingStage, removeTraits, cancelRemoveTraits,
    removeApplication, cancelRemoveApplication, cancelRemoveTechnology, removeTechnology, removeMarkerTraits, cancelRemoveMarkerTraits,
    removePhenotypeTraits, cancelRemovePhenotypeTraits, cancelRemoveBreedingPool, removeBreedingPool
} from '../../../actions/breedingStage';


const BreedingStageDialog = (props) => {
    const dispatch = useDispatch()
    const breedingStageFormsState = useSelector((state) => state.breedingStageForms)

    const handleDuplicateBreedingStage = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(duplicateBreedingStage(breedingStageFormsState.duplicateStageForm.breedingStageId, props.breedingSchemeId, breedingStageFormsState.duplicateStageForm.PoolId))
    }

    const handleCancelDuplicateBreedingStage = () => {
        dispatch(cancelDuplicateBreedingStage())
    }

    const handleRemoveBreedingStage = () => {
        //Passed arguments are the breeding stage ID, Pool Id & scheme Id for refreshing the grid
        dispatch(removeBreedingStage(breedingStageFormsState.removeStageForm.breedingStageId, props.breedingSchemeId, breedingStageFormsState.removeStageForm.PoolId))
    }

    const handleCancelRemoveBreedingStage = () => {
        dispatch(cancelRemoveBreedingStage())
    }

    const handleRemoveTechnology = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removeTechnology(breedingStageFormsState.removeGenotypeTechnologyForm.technologyGroupId, props.breedingSchemeId, breedingStageFormsState.removeGenotypeTechnologyForm.PoolId))
    }

    const handleCancelRemoveTechnology = () => {
        dispatch(cancelRemoveTechnology())
    }

    const handleRemoveApplication = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removeApplication(breedingStageFormsState.removeGenotypeTechnologyApplicationForm.applicationGroupId, props.breedingSchemeId, breedingStageFormsState.removeGenotypeTechnologyApplicationForm.PoolId))
    }

    const handleCancelRemoveApplication = () => {
        dispatch(cancelRemoveApplication())
    }

    const handleRemoveTrait = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid        
        dispatch(removeTraits(breedingStageFormsState.removeTraitsForm.traitsGroupId, props.breedingSchemeId, breedingStageFormsState.removeTraitsForm.PoolId))
    }

    const handleCancelRemoveTrait = () => {
        dispatch(cancelRemoveTraits())
    }

    const handleRemoveMarkerTrait = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removeMarkerTraits(breedingStageFormsState.removeMarkerTraitsForm.traitsGroupId, props.breedingSchemeId, breedingStageFormsState.removeMarkerTraitsForm.PoolId))
    }

    const handleCancelRemoveMarkerTrait = () => {
        dispatch(cancelRemoveMarkerTraits())
    }

    const handleRemovePhenotypeTrait = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removePhenotypeTraits(breedingStageFormsState.removePhenotypeTraitsForm.traitsGroupId, props.breedingSchemeId, breedingStageFormsState.removePhenotypeTraitsForm.PoolId))
    }

    const handleCancelRemovePhenotypeTrait = () => {
        dispatch(cancelRemovePhenotypeTraits())
    }

    const handleRemoveBreedingPool = () => {
        dispatch(removeBreedingPool(breedingStageFormsState.removePoolForm.BreedingSchemeId, breedingStageFormsState.removePoolForm.PoolId))
    }

    const handleCancelRemoveBreedingPool = () => {
        dispatch(cancelRemoveBreedingPool())
    }

    return (
        <>
            <UserDialog
                open={breedingStageFormsState.removePoolForm.open}
                contentTitle="Are you sure you want to remove the selected pool?"
                contentText="Note: All breeding stages defined under this pool will also be deleted!"
                handleAction={handleRemoveBreedingPool}
                handleCancel={handleCancelRemoveBreedingPool}
            />
            <UserDialog
                open={breedingStageFormsState.duplicateStageForm.open}
                contentTitle="Are you sure you want to create a copy of the selected breeding stage?"
                contentText=""
                handleAction={handleDuplicateBreedingStage}
                handleCancel={handleCancelDuplicateBreedingStage}
            />
            <UserDialog
                open={breedingStageFormsState.removeStageForm.open}
                contentTitle="Are you sure you want to remove the selected breeding stage?"
                contentText=""
                handleAction={handleRemoveBreedingStage}
                handleCancel={handleCancelRemoveBreedingStage}
            />
            <UserDialog
                open={breedingStageFormsState.removeGenotypeTechnologyForm.open}
                contentTitle="Are you sure you want to remove the selected Genotyping Technology?"
                contentText="Note: This will remove the selected technology from all breeding stages under this breeding scheme"
                handleAction={handleRemoveTechnology}
                handleCancel={handleCancelRemoveTechnology}
            />
            <UserDialog
                open={breedingStageFormsState.removeTraitsForm.open}
                contentTitle="Are you sure you want to remove the selected trait?"
                contentText="Note: This will remove the selected trait from all breeding stages under this breeding scheme"
                handleAction={handleRemoveTrait}
                handleCancel={handleCancelRemoveTrait}
            />
            <UserDialog
                open={breedingStageFormsState.removeGenotypeTechnologyApplicationForm.open}
                contentTitle="Are you sure you want to remove the selected Genotyping Technology Application?"
                contentText="Note: This will remove the selected application from all breeding stages under this breeding scheme"
                handleAction={handleRemoveApplication}
                handleCancel={handleCancelRemoveApplication}
            />
            <UserDialog
                open={breedingStageFormsState.removeMarkerTraitsForm.open}
                contentTitle="Are you sure you want to remove the selected Marker-Assisted Selection Trait?"
                contentText="Note: This will remove the selected trait from all breeding stages under this breeding scheme"
                handleAction={handleRemoveMarkerTrait}
                handleCancel={handleCancelRemoveMarkerTrait}
            />
            <UserDialog
                open={breedingStageFormsState.removePhenotypeTraitsForm.open}
                contentTitle="Are you sure you want to remove the selected Phenotype Screening Trait?"
                contentText="Note: This will remove the selected trait from all breeding stages under this breeding scheme"
                handleAction={handleRemovePhenotypeTrait}
                handleCancel={handleCancelRemovePhenotypeTrait}
            />
        </>
    )
}

export default BreedingStageDialog
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import Divider from '@material-ui/core/Divider'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/People'
import Tooltip from '@material-ui/core/Tooltip'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        '& > div': {
            width: "62px",
            overflowX: "hidden",
            position: "relative"
        }
    },
    profileButton: {
        fontSize: "2rem"
    },
    profileItem: {
        backgroundColor: "#ecf0f5",
        padding: "17px 15px"
    },
    sectionDivider: {
        marginBottom: "10px"
    }
}))

const Navigation = () => {
    const history = useHistory()
    const userAuth = useSelector((state) => state.userAuth)

    const classes = useStyles()
    return (
        <Paper variant="elevation">
            <Drawer variant="permanent" className={classes.root}>
                <List disablePadding>
                    {/* <Tooltip title="Account Profile" arrow placement="right-start">
                        <ListItem className={classes.profileItem} button onClick={() => history.push("/profile")}>
                            <AccountCircleIcon className={classes.profileButton}><ListAltIcon color="primary" /></AccountCircleIcon>
                        </ListItem>
                    </Tooltip>
                    <Divider className={classes.sectionDivider} /> */}
                    <Tooltip title="Breeding Schemes" arrow placement="right-start">
                        <ListItem button onClick={() => history.push("/")}>
                            <ViewComfyIcon><ListAltIcon color="primary" /></ViewComfyIcon>
                        </ListItem>
                    </Tooltip>                    
                    <Divider className={classes.sectionDivider} />
                    {userAuth.user.role === "admin" &&
                        <>
                            <Tooltip title="Manage Users" arrow placement="right-start">
                                <ListItem button onClick={() => history.push("/users")}>
                                    <ListItemIcon><PeopleIcon color="primary" /></ListItemIcon>
                                </ListItem>
                            </Tooltip>
                            <Divider />
                        </>
                    }
                </List>
            </Drawer>
        </Paper>
    )
}

export default Navigation
import { FETCH_PROFILE_REQUEST, FETCH_PROFILE_RESPONSE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_RESPONSE } from '../constants/actionTypes'

const initialState = {
    data: {
        fullName: "",
        gender: "",
        //dateOfBirth: "",
        email: "",
        institution: "",
        position: "",
        country: "",
        createdAt: "",
        breedingSchemes: 0
    },
    isLoading: false,
    success: false,
    message: null
}

const reducer = (userProfile = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE_REQUEST:
            return {
                ...userProfile,
                isLoading: true,
            }
        case FETCH_PROFILE_RESPONSE:
            return {
                ...action.payload,
                isLoading: false,
            }
        case UPDATE_PROFILE_REQUEST:
            return {
                ...userProfile,
                isLoading: true,
            }
        case UPDATE_PROFILE_RESPONSE:
            return {
                ...userProfile,
                isLoading: false,
            }
        default:
            return userProfile
    }
}

export default reducer